import React, { useRef, useState } from "react";
import { TERipple } from "tw-elements-react";

const RadioButton = ({ setFormData, formData, type, name, from, index, className, id, required, disabled, label }) => {
  const inputRef = useRef(null),
    rippleRef = useRef(null);

  const checked =
    type === "question" ? (formData[from][index].a === true && id === "yes") || (formData[from][index].a === false && id === "no") : from ? formData[from] === id : formData[name];
  const newId = type === "question" ? from + "-" + index + "-" + id : from ? from + "-" + id : id;

  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const onChange = (event) => {
    const checked = event.target.checked,
      value = event.target.value;

    if (type === "question") {
      let array = [...formData[from]];
      array[index].a = id === "yes" ? true : id === "no" ? false : "";

      setFormData({
        ...formData,
        [from]: array
      });
    } else {
      if (!from) {
        setFormData({
          ...formData,
          [name]: checked
        });
      } else {
        setFormData({
          ...formData,
          [from]: value
        });
      }
    }
  };

  const triggerRipple = () => {
    let opts = { view: window, bubbles: true, cancelable: true, buttons: 1 };
    rippleRef.current.dispatchEvent(new MouseEvent("mousedown", opts));
  };

  return (
    <div className={`${className}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <input ref={inputRef} type="radio" value={id} checked={checked} onChange={onChange} name={name} id={newId} required={required} className={`hidden`} />
      <TERipple ref={rippleRef} rippleRadius={30} rippleDuration={250} rippleCentered rippleUnbound rippleColor={"#AAAAAA"} className={disabled ? "pointer-events-none" : ""}>
        <div
          className={`w-5 h-5 mb-0.5 rounded-full cursor-pointer ${
            checked
              ? `bg-hoptop-500 radio-mask ${hovered && !disabled ? "bg-hoptop-600 dark:bg-hoptop-400" : ""} ${disabled ? "!bg-hoptop-300 dark:!bg-hoptop-600" : ""}`
              : `border-2 border-neutral-350 ${hovered && !disabled ? "!border-neutral-500 dark:!border-neutral-100" : ""} ${
                  disabled ? "!border-neutral-200 dark:!border-neutral-500" : ""
                }`
          } focus-visible:outline dark:focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-2`}
          onKeyDown={(event) => {
            if (!disabled && event.code === "Space") {
              inputRef.current.click();
              triggerRipple();
            }
          }}
          onClick={() => {
            if (!disabled) {
              inputRef.current.click();
            }
          }}
          tabIndex={0}
        />
      </TERipple>
      <label htmlFor={newId} className={`ml-1.5 cursor-pointer ${disabled ? "!text-neutral-350 dark:!text-neutral-500 pointer-events-none" : ""}`} onClick={triggerRipple}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
