/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import axios from "axios";
import i18n from "../common/language/i18n";

import useAuth from "./useAuth";
import useLogger from "./useLogger";

const useLanguage = () => {
  const { isLoggedIn } = useAuth();
  const { showResponse } = useLogger();

  const setLanguage = async (code) => {
    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + "users/edit", { preferredLanguage: code }, { withCredentials: true })
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const changeLanguage = (code) => {
    localStorage.setItem("lang", code);

    if (isLoggedIn()) {
      setLanguage(code);
    }
  };

  useEffect(() => {
    i18n.on("languageChanged", changeLanguage);

    return () => {
      i18n.off("languageChanged", changeLanguage);
    };
  }, []);
};

export default useLanguage;
