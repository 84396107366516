import { useContext } from "react";

import { SettingsFormDataContext } from "../contexts/SettingsFormDataContext";

const useJSDirections = () => {
  const settingsFormDataContext = useContext(SettingsFormDataContext);

  const mfaAuthenticatorButtons = () => {
    return settingsFormDataContext.formData.mfaAuthenticatorIsSetup ? "horizontal" : "vertical";
  };

  const mfaSMSButtons = () => {
    return settingsFormDataContext.formData.mfaSMSIsSetup ? "horizontal" : "vertical";
  };

  const mfaEmailButtons = () => {
    return settingsFormDataContext.formData.mfaEmailIsSetup ? "horizontal" : "vertical";
  };

  const mfaCodesButtons = () => {
    return settingsFormDataContext.formData.mfaCodesIsSetup ? "horizontal" : "vertical";
  };

  return { mfaAuthenticatorButtons, mfaSMSButtons, mfaEmailButtons, mfaCodesButtons };
};

export default useJSDirections;
