import useConversations from "../../../hooks/useConversations";

import { dotPulse } from "ldrs";

const Message = ({ message, lastInBunch, loading }) => {
  dotPulse.register();

  const { userIsAuthor } = useConversations();

  return (
    <div
      className={`relative w-fit max-w-3/4 p-4 rounded-md ${lastInBunch && "message-arrow"} ${
        userIsAuthor(message)
          ? "bg-blue-500 text-white dark:bg-blue-900 mr-3 self-end message-arrow-right"
          : "bg-neutral-250 dark:bg-neutral-950 ml-3 self-start message-arrow-left"
      }`}
    >
      {loading ? (
        <l-dot-pulse size="43" speed="1.3" color={localStorage.getItem("theme") === "light" ? "#464646" : "#FAFAFA"} />
      ) : (
        <p className="whitespace-break-spaces break-words">{message.text}</p>
      )}
    </div>
  );
};

export default Message;
