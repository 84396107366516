import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMotionValueEvent, AnimatePresence, useScroll, motion } from "framer-motion";
import { Link } from "react-scroll";

import useAuth from "../../hooks/useAuth";
import useImagePreloader from "../../hooks/useImagePreloader";

import { FiMenu, FiX } from "react-icons/fi";
import HeaderLogo from "../../images/temp/header_logo_light.png";
import HeaderLogoDark from "../../images/temp/header_logo_dark.png";

import Avatar from "../../components/avatar/Avatar";
import HeaderButton from "./HeaderButton";
import HeaderButtonNav from "./HeaderButtonNav";
import LanguageSelector from "../language/LanguageSelector";
import Popup from "../../components/popup/Popup";
import PopupButton from "../../components/popup/PopupButton";
import Separator from "../../components/Separator";
import ThemeSwitcher from "../../components/themeSwitcher/ThemeSwitcher";

const preloadSrcList = [HeaderLogo, HeaderLogoDark];

const Example = () => {
  return (
    <>
      <FlyoutNav />
      {window.location.pathname === "/" || window.location.pathname === "/businesses" ? (
        <div className="absolute inset-0 z-0 bg-gradient-to-b from-neutral-700/50 to-neutral-250/0 dark:bg-gradient-to-b dark:from-neutral-950/90 dark:to-neutral-250/0 pointer-events-none" />
      ) : null}
      <div className="bg-neutral-300 dark:bg-neutral-50" />
    </>
  );
};

const FlyoutNav = () => {
  const [scrolled, setScrolled] = useState(false);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrolled(latest > 250 ? true : false);
  });

  return (
    <nav
      className={`fixed top-0 z-50 w-full px-6 text-white
      transition-all duration-300 ease-out lg:px-12
      ${scrolled ? "bg-neutral-200 dark:bg-neutral-950 py-3 shadow-sm" : "bg-neutral-200/0 dark:bg-neutral-950/0 py-4 shadow-none"}`}
    >
      <div className="mx-auto flex max-w-7xl items-center justify-between">
        <Logo />
        <div className="hidden lg:flex flex-1 justify-end items-center gap-x-4">
          <Links />
          <div className="ml-6">
            <Setting />
          </div>
          <Profile />
        </div>
        <MobileMenu />
      </div>
    </nav>
  );
};

const Logo = () => {
  useImagePreloader(preloadSrcList);

  return (
    <div className="flex items-center gap-2 mx-[100px]">
      <a href="/" className="-m-3 p-1.5 flex items-center">
        <img className="w-20 h-auto mr-5" src={localStorage.theme === "light" ? HeaderLogo : HeaderLogoDark} alt="logo" />{" "}
      </a>
    </div>
  );
};

const Links = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();

  if (window.location.pathname.includes("policy")) {
    return (
      <div className="flex items-center gap-6 text-black dark:text-white ">
        {isLoggedIn() && <HeaderButton link="/dashboard" text={t("dashboard")} />}
        <HeaderButton link="/" text={t("services")} />
        <HeaderButton link="/" text={t("resources")} />
        {/* <HeaderButtonNav to="blogue" text={t("blog")} smooth={true} duration={500} /> */}
        <HeaderButton link="/" text={t("team")} />
      </div>
    );
  }

  if (window.location.pathname.includes("businesses")) {
    return (
      <div className="flex items-center gap-6 text-black dark:text-white ">
        {isLoggedIn() && <HeaderButton link="/dashboard" text={t("dashboard")} />}
        <HeaderButtonNav to="services" text={t("services")} smooth={true} duration={500} />
        <HeaderButtonNav to="resources" text={t("resources")} smooth={true} duration={500} />
        <HeaderButtonNav to="pricing" text={t("pricing")} smooth={true} duration={500} />
        {/*<HeaderButtonNav to="blogue" text={t("blog")} smooth={true} duration={500} /> */}
        <HeaderButtonNav to="team" text={t("team")} smooth={true} duration={500} />
      </div>
    );
  } else {
    return (
      <div className="flex items-center gap-6 text-black dark:text-white ">
        {isLoggedIn() && <HeaderButton link="/dashboard" text={t("dashboard")} />}
        <HeaderButtonNav to="services" text={t("services")} smooth={true} duration={500} />
        <HeaderButtonNav to="resources" text={t("resources")} smooth={true} duration={500} />
        {/* <HeaderButtonNav to="blogue" text={t("blog")} smooth={true} duration={500} /> */}
        <HeaderButtonNav to="team" text={t("team")} smooth={true} duration={500} />
      </div>
    );
  }
};

const Setting = () => {
  return (
    <div className="flex items-center gap-2">
      <LanguageSelector />
      <div className="min-w-4 max-w-6 -mt-[2px]">
        <ThemeSwitcher />
      </div>
    </div>
  );
};

const Profile = () => {
  const { t } = useTranslation();

  const { logOut, isLoggedIn, fullName } = useAuth();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownOpen && !dropdownRef.current?.contains(event.target) && !dropdownButtonRef.current?.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <div className="relative">
      <div
        ref={dropdownButtonRef}
        className="w-10 h-10 cursor-pointer"
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <Avatar image={null} alt={"Profile menu"} className="shadow-md" />
      </div>
      <Popup
        position={window.matchMedia("(max-width: 1024px)").matches ? "top" : "bottom"}
        width="w-max"
        height="h-fit"
        shown={dropdownOpen}
        modifiers={window.matchMedia("(max-width: 1024px)").matches ? "end" : ""}
      >
        <div ref={dropdownRef} className="flex flex-col space-y-1">
          {!isLoggedIn() && (
            <>
              <PopupButton link="/login">{t("login")}</PopupButton>
              <PopupButton link="/sign-up">{t("signup")}</PopupButton>
            </>
          )}
          {isLoggedIn() && (
            <>
              <PopupButton>
                <div className="flex items-center space-x-3">
                  <Avatar image={null} alt={"Profile picture"} className={"!w-10 !h-10"} />
                  <p className="flex-1 font-semibold">{fullName()}</p>
                </div>
              </PopupButton>
              <Separator />
              <PopupButton link="/dashboard">{t("dashboard")}</PopupButton>
              <PopupButton link="/dashboard/settings">{t("dashboardSettings")}</PopupButton>
              <PopupButton textColor="text-red-500 dark:text-red-600" action={logOut}>
                {t("logout")}
              </PopupButton>
            </>
          )}
        </div>
      </Popup>
    </div>
  );
};

const MobileMenuLink = ({ text, to, smooth = false, duration = 500, FoldContent, setMenuOpen }) => {
  const [open, setOpen] = useState(false);

  const handleLinkClick = (e) => {
    e.stopPropagation();
    setMenuOpen(false);
  };

  return (
    <div className="relative text-neutral-950 dark:text-neutral-200">
      {FoldContent ? (
        <>
          <div
            className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
            onClick={() => setOpen(!open)}
          >
            {smooth ? (
              <Link to={to} smooth={smooth} duration={duration} onClick={handleLinkClick}>
                {text}
              </Link>
            ) : (
              <a href={to} onClick={handleLinkClick}>
                {text}
              </a>
            )}
            <motion.div animate={{ rotate: open ? "180deg" : "0deg" }} transition={{ duration: 0.3, ease: "easeOut" }}></motion.div>
          </div>
          <motion.div
            initial={false}
            animate={{
              height: open ? "auto" : "0px",
              opacity: open ? 1 : 0
            }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            {FoldContent}
          </motion.div>
        </>
      ) : smooth ? (
        <Link
          to={to}
          smooth={smooth}
          duration={duration}
          className="flex w-full items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold cursor-pointer"
          onClick={handleLinkClick}
        >
          {text}
        </Link>
      ) : (
        <a
          href={to}
          onClick={handleLinkClick}
          className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
        >
          {text}
        </a>
      )}
    </div>
  );
};

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();

  const LINKS = [
    ...(isLoggedIn() ? [{ text: t("dashboard"), to: "/dashboard", smooth: false }] : []),
    { text: t("services"), to: "services", smooth: true, duration: 500 },
    { text: t("resources"), to: "resources", smooth: true, duration: 500 },
    ...(window.location.pathname.includes("businesses")
      ? [
          {
            text: t("pricing"),
            to: "pricing",
            smooth: true,
            duration: 500
          }
        ]
      : []),
    { text: t("team"), to: "team", smooth: true, duration: 500 }
  ];

  return (
    <div className="block lg:hidden">
      <button onClick={() => setOpen(true)} className="text-3xl">
        <FiMenu />
      </button>
      <AnimatePresence>
        {open && (
          <motion.nav
            initial={{ x: "100vw" }}
            animate={{ x: 0 }}
            exit={{ x: "100vw" }}
            transition={{ duration: 0.15, ease: "easeOut" }}
            className="fixed left-0 top-0 z-50 flex h-screen w-full flex-col bg-neutral-200 dark:bg-neutral-950"
          >
            <div className="flex items-center justify-between p-6">
              <Logo />
              <button onClick={() => setOpen(false)} className="text-3xl text-neutral-950 dark:text-white">
                <FiX />
              </button>
            </div>
            <div className=" bg-neutral-100 dark:bg-neutral-700 p-6 h-screen">
              {LINKS.map((link) => (
                <MobileMenuLink key={link.text} text={link.text} to={link.to} smooth={link.smooth} duration={link.duration} setMenuOpen={setOpen} />
              ))}
            </div>
            <div className="flex items-center justify-end bg-neutral-200 dark:bg-neutral-950 p-6 space-x-2 text-lg text-white dark:text-white">
              {isLoggedIn() && <HeaderButton link="/dashboard" text={t("dashboard")} />}
              <Profile />
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Example;
