import React from "react";
import { useTranslation } from "react-i18next";

const TenderInfoBox = ({ type, info, color, modifiers = "" }) => {
  const { t } = useTranslation();

  const COLORS = {
    default: {
      bg: ` ${modifiers.includes("tempTenderCardPriority") && "!bg-neutral-50"} `,
      text: "",
      border: "border-neutral-800 dark:border-neutral-50"
    },
    red: {  text: "text-red-600 dark:text-red-100", border: "border-red-900 dark:border-red-100" },
    orange: {text: "text-orange-600 dark:text-orange-100", border: "border-orange-900 dark:border-orange-100" },
    yellow: {  text: "text-yellow-500 dark:text-yellow-100", border: "border-yellow-900 dark:border-yellow-100" },
    green: {  text: "text-green-700 dark:text-green-100", border: "border-green-900 dark:border-green-100" },
    blue: {  text: "text-blue-800 dark:text-blue-100", border: "border-blue-900 dark:border-blue-100" },
    greenWild : { text: "text-green-900 dark:text-green-200", border: "border-green-800 dark:border-green-200" },
    redFort: {  text: "text-red-800 dark:text-red-200", border: "border-red-800 dark:border-red-200" },

  };


  if (modifiers.includes("tempTenderCardPriority")) {
    return (
        <div className="flex items-center justify-center p-1">
            <p className="text-center">{info}</p>
        </div>
    );
}

  return (
    <div className={`flex items-center justify-center min-w-20 rounded-full text-center ${COLORS[color].bg}`}>
        {!modifiers.includes("no-type") && (
            <p className={`font-semibold pr-1.5 border-r ${COLORS[color].text} ${COLORS[color].border}`}>
                {t(type)}
            </p>
        )}
        <p className={`font-semibold uppercase ${!modifiers.includes("no-type") && "pl-1.5"} ${COLORS[color].text}`}>
            {info}
        </p>
    </div>
);
};

export default TenderInfoBox;
