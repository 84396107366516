import React, { useRef, useState } from "react";
import { IconContext } from "react-icons";

import useAuth from "../../hooks/useAuth";

import Avatar from "../avatar/Avatar";
import ProfilePopup from "./profilePopup/ProfilePopup";
import Popup from "../popup/Popup";

const SidebarButton = ({ type, image, alt, subimage, subalt, icon, label, sublabel, link, action, sidebarExpanded, popupOpen, togglePopup }) => {
  const { isCompany } = useAuth();

  const buttonRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);

  let timeout;

  const contents = (
    <>
      <div className={`min-h-8 aspect-square ${type === "subscribe" ? "!min-h-10 -ml-px" : ""}`}>
        {type === "avatar" && <Avatar image={image} alt={alt} subimage={subimage} subalt={subalt} />}
        {image && <>{type !== "avatar" && <img src={image} alt={alt} className="h-full m-auto" />}</>}
        {icon && type !== "theme" && <IconContext.Provider value={{ className: "w-full h-full" }}>{icon}</IconContext.Provider>}
        {icon && type === "theme" && icon}
      </div>
      {sidebarExpanded && (
        <div className="min-w-52 max-w-52">
          <p
            className={`text-sm ${type === "logo" && "text-xl font-bold"} ${type === "avatar" && "font-semibold"} ${
              type === "subscribe" && "font-bold leading-10"
            } whitespace-nowrap overflow-hidden text-ellipsis`}
          >
            {label}
          </p>
          {/* TODO Change subimage !== undefined when company logos will be implemented */}
          {type === "avatar" && isCompany() && subimage !== undefined && <p className="text-2xs leading-3 whitespace-nowrap overflow-hidden text-ellipsis">{sublabel}</p>}
        </div>
      )}
    </>
  );

  return (
    <>
      <div
        ref={buttonRef}
        className="relative"
        onMouseEnter={() => {
          timeout = setTimeout(() => {
            setIsHovered(true);
          }, 500);
        }}
        onMouseLeave={() => {
          clearTimeout(timeout);
          setIsHovered(false);
        }}
      >
        {link && (
          <a href={link} className={`flex items-center space-x-4 ${type === "logo" && "!space-x-2"} ${type === "subscribe" && "!space-x-3"}`}>
            {contents}
          </a>
        )}
        {action && (
          <button onClick={action} className={`flex items-center text-start space-x-4 ${type === "logo" && "!space-x-2"} ${type === "subscribe" && "!space-x-3"}`}>
            {contents}
          </button>
        )}
        {type === "avatar" && <ProfilePopup sidebarExpanded={sidebarExpanded} popupOpen={popupOpen} togglePopup={togglePopup} buttonRef={buttonRef} />}
        {type === "avatar" && (
          <Popup position="right" width="w-max" height="h-fit" background="bg-neutral-600 dark:bg-neutral-700" shown={isHovered && !sidebarExpanded && !popupOpen}>
            <div className="relative p-0.5 z-10">
              <p className="text-xs font-semibold">{label}</p>
              {isCompany() && <p className="text-2xs">{sublabel}</p>}
            </div>
          </Popup>
        )}
      </div>
    </>
  );
};

export default SidebarButton;
