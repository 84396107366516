import React from "react";
import Avatar from "../../avatar/Avatar";

const AvatarInfoBox = ({ avatar, name, modifiers = "" }) => {
  return (
    <div className={`flex items-center space-x-2 ${modifiers.includes("space-lg") ? "space-x-4" : ""}`}>
      <div className={`w-6 h-6 ${modifiers.includes("avatar-sm") ? "!w-8 !h-8" : ""} ${modifiers.includes("avatar-xl") ? "!w-16 !h-16" : ""}`}>
        <Avatar image={avatar} alt={"Profile picture for " + name} />
      </div>
      <p
       className={`${modifiers.includes("text-sm") ? "text-sm" : ""} ${modifiers.includes("text-2xl") ? "text-2xl" : ""} ${
          !modifiers.includes("text-not-bold") ? "font-semibold" : ""
        }`}
      >
        {name}
      </p>
    </div>
  );
};

export default AvatarInfoBox;
