import { useContext } from "react";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";

import useAuth from "./useAuth";
import { SelectedConvoContext } from "../contexts/SelectedConvoContext";
import Popup from "../components/popup/Popup";

const useConversations = () => {
  const { t } = useTranslation();
  const { userId } = useAuth();
  const selectedConvo = useContext(SelectedConvoContext);

  const getDayOfYear = (date) => {
    return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24));
  };

  const isConvoSelected = () => {
    if (selectedConvo.conversation) {
      return true;
    }

    return false;
  };

  const getRecipientName = (recipient) => {
    if ("fullName" in recipient) {
      return recipient.fullName;
    } else if ("displayName" in recipient) {
      return recipient.displayName;
    }

    return "";
  };

  const getSelectedRecipientName = () => {
    return getRecipientName(selectedConvo.recipient);
  };

  const getSelectedProfilePic = () => {
    return getProfilePic(selectedConvo.profilePic);
  };

  const messageTimestamp = (message) => {
    // TODO Make these look like the actual thing, and have them hover and all (not in this file tho)
    return message.timestamp;
  };

  // SEPARATOR

  const getRecipientId = (conversation) => {
    if (conversation.client) {
      return conversation.client;
    } else if (conversation.company) {
      return conversation.company;
    }

    return null;
  };

  const getProfilePic = (buffer) => {
    if (buffer) {
      let base64ImageString = Buffer.from(buffer.data, "binary").toString("base64");

      return "data:image/png;base64," + base64ImageString;
    }

    return null;
  };

  const userIsAuthor = (message) => {
    return message?.author === userId();
  };

  const messagePreviewTimestamp = (timestamp, ref, hovered, setHovered) => {
    const messageTimestamp = new Date(timestamp);
    const today = getDayOfYear(new Date());

    const hoverDate = messageTimestamp.toLocaleString();

    let displayedDate;
    if (getDayOfYear(messageTimestamp) === today) {
      displayedDate = messageTimestamp.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    } else if (getDayOfYear(messageTimestamp) === today - 1) {
      displayedDate = t("yesterday");
    } else {
      displayedDate = messageTimestamp.toLocaleDateString();
    }

    return (
      <div className="relative">
        <Popup position="top" width="w-max" height="h-fit" background="bg-neutral-400 dark:bg-neutral-950" className="!mb-3 !rounded-md" shown={hovered}>
          <p className="relative z-40">{hoverDate}</p>
        </Popup>
        <p
          ref={ref}
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
        >
          {displayedDate !== "Invalid Date" ? displayedDate : ""}
        </p>
      </div>
    );
  };

  const sortConversations = (conversations) => {
    conversations.sort((a, b) => {
      if (a.messages.length > 0 && b.messages.length > 0) {
        const timeA = new Date(a.messages[0].createdAt),
          timeB = new Date(b.messages[0].createdAt);

        if (timeA < timeB) {
          return 1;
        } else if (timeA > timeB) {
          return -1;
        }

        return 0;
      } else if (a.messages.length === 0 && b.messages.length > 0) {
        return 1;
      } else if (b.messages.length === 0 && a.messages.length > 0) {
        return -1;
      }

      return 0;
    });
  };

  return {
    isConvoSelected,
    getRecipientName,
    getSelectedRecipientName,
    getSelectedProfilePic,
    messageTimestamp,

    // SEPARATOR

    getRecipientId,
    getProfilePic,
    userIsAuthor,
    messagePreviewTimestamp,
    sortConversations
  };
};

export default useConversations;
