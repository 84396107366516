import React from "react";
import { useTranslation } from "react-i18next";

import SmallServiceOfferCard from "../card/dashboard/SmallServiceOfferCard";

const SmallServiceOffersList = ({ serviceOffers, refreshServiceOffers }) => {
  const { t } = useTranslation();

  return serviceOffers.length !== 0 ? (
    serviceOffers.map((offer, i) => {
      return <SmallServiceOfferCard key={"serviceOffer-" + i} offer={offer} refreshServiceOffers={refreshServiceOffers} />;
    })
  ) : (
    <div className="flex items-center justify-center border-2 border-dashed border-neutral-400 w-full min-h-40 shadow-md rounded-lg">{t("noServiceOffers")}</div>
  );
};

export default SmallServiceOffersList;
