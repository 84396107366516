import useStripe from "../../hooks/useStripe";

const useJSLinks = () => {
  const { getSubscription } = useStripe();

  const stripeAccess = () => {
    return getSubscription()?.paymentURL;
  };

  return { stripeAccess };
};

export default useJSLinks;
