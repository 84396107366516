/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import Card from "../../../components/card/Card";
import BigServiceOffersList from "../../../components/serviceOffers/BigServiceOffersList";

const DashboardMyServiceOffers = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [myServiceOffers, setMyServiceOffers] = useState([]);

  const getServiceOffers = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `companies/${selectedCompany.id}/service-offers`, { withCredentials: true })
      .then((response) => {
        setMyServiceOffers(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    if (selectedCompany?.isNull === false) {
      getServiceOffers();
    }
  }, [selectedCompany?.isNull]);

  return (
    <div>
      <Card title={t("dashboardMyServiceOffers")}>
        <BigServiceOffersList offers={myServiceOffers} />
      </Card>
    </div>
  );
};

export default DashboardMyServiceOffers;
