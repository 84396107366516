/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { motion } from "framer-motion";

import useTenders from "../../hooks/useTenders";

import { MdKeyboardArrowDown } from "react-icons/md";

const TenderQuestionsAccordion = ({ tender, questions, defaultValue, className }) => {
  const { showQuestionAnswer, showQuestion } = useTenders();

  const [openStates, setOpenStates] = useState([]);

  const toggleState = (i) => {
    let temp = [...openStates];

    temp[i] = !temp[i];

    setOpenStates(temp);
  };

  const fillStatesArray = () => {
    let temp = [];

    questions.forEach(() => {
      temp.push(defaultValue);
    });

    setOpenStates(temp);
  };

  useEffect(() => {
    fillStatesArray();
  }, []);

  if (questions) {
    return (
      <div className={`bg-neutral-150 dark:bg-neutral-800 rounded-lg h-96 !my-6 px-2 overflow-y-scroll scrollbar-preset-thin ${className}`}>
        {questions.map((question, i) => {
          return (
            <div key={"question-" + i} className={`${i !== 0 ? "border-t" : ""} border-neutral-200 dark:border-neutral-500 px-1 py-3`}>
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => {
                  toggleState(i);
                }}
              >
                <p className="font-semibold">{showQuestion(tender, question.q)}</p>
                <IconContext.Provider value={{ className: "w-5 h-5" }}>
                  <motion.div animate={{ rotate: openStates[i] ? "180deg" : "0deg" }}>
                    <MdKeyboardArrowDown />
                  </motion.div>
                </IconContext.Provider>
              </div>
              <motion.div
                animate={{
                  height: openStates[i] ? "fit-content" : "0px"
                }}
                className="overflow-hidden"
              >
                <p className="pt-1">{showQuestionAnswer(question.a)}</p>
              </motion.div>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default TenderQuestionsAccordion;
