/* eslint-disable jsx-a11y/img-redundant-alt */

import React from "react";

import useImagePreloader from "../../hooks/useImagePreloader";
import useAuth from "../../hooks/useAuth";

import noProfilePic from "../../images/temp/blank-profile-picture.png";

const preloadSrcList = [noProfilePic];

const Avatar = ({ image, alt, subimage, subalt, status, className }) => {
  useImagePreloader(preloadSrcList);

  const { isCompany } = useAuth();

  return (
    <div className="relative">
      <img
        src={image !== null && image !== undefined ? image : noProfilePic}
        alt={alt !== null && alt !== undefined ? alt : "No profile picture"}
        /* TODO Change subimage !== undefined when company logos will be implemented */
        className={`w-full h-full object-cover rounded-full ${isCompany() && subimage !== undefined && "company-icon-mask"} ${status && "status-icon-mask"} ${className}`}
      />

      {/* TODO Change subimage !== undefined when company logos will be implemented */}
      {isCompany() && subimage !== undefined && (
        <img
          src={subimage !== null && subimage !== undefined ? subimage : noProfilePic}
          alt={subalt !== null && subalt !== undefined ? subalt : "No image"}
          className="absolute w-1/2 rounded-full bottom-0 right-0"
        />
      )}

      {status === "online" && <div className="absolute bg-green-600 w-1/3 h-1/3 rounded-full bottom-0 right-0" />}
      {status === "away" && <div className="absolute bg-yellow-600 w-1/3 h-1/3 rounded-full bottom-0 right-0 status-mask-away" />}
      {/* TODO Fix the DND icon */}
      {status === "dnd" && (
        <div className="absolute bg-red-600 w-1/3 h-1/3 rounded-full bottom-0 right-0 status-mask-dnd">
          <svg width="100%" height="100%">
            <mask id="dnd-mask">
              <rect fill="#fff" width="100%" height="100%" />
              <rect fill="#000" width="75%" height="20%" x="50%" y="50%" rx="10%" ry="10%" className="-translate-x-[calc(50%)] -translate-y-[calc(20%)]" />
            </mask>
          </svg>
        </div>
      )}
      {status === "offline" && <div className="absolute bg-neutral-400 w-1/3 h-1/3 rounded-full bottom-0 right-0 status-mask-offline" />}
    </div>
  );
};

export default Avatar;
