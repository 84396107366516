/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import Card from "../../../components/card/Card";
import TendersList from "../../../components/tenders/TendersList";

const DashboardMyProjects = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [projects, setProjects] = useState([]);

  const getTenders = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `companies/${selectedCompany.id}/projects`, {
        withCredentials: true
      })
      .then((response) => {
        setProjects(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    if (selectedCompany.id) {
      getTenders();
    }
  }, [selectedCompany]);

  return (
    <div>
      <Card title={t("dashboardProjects")}>
        <TendersList type="myProjects" tenders={projects} />
      </Card>
    </div>
  );
};

export default DashboardMyProjects;
