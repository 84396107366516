import React from "react";

import DashboardSettings from "../DashboardSettings";

const SubscriptionSettings = () => {
  const CATEGORY_ID = "general";
  const TAB_ID = "subscription";

  return <DashboardSettings categoryId={CATEGORY_ID} tabId={TAB_ID} />;
};

export default SubscriptionSettings;
