import React from "react";
import { useTranslation } from "react-i18next";

import useForm from "../../hooks/useForm";

import Button from "../button/Button";

const Form = ({ type, content, formData, setFormData, action, buttonLabel, className, modifiers = "" }) => {
  const { t } = useTranslation();

  const { inputByType } = useForm();

  // TODO Add required to all inputs and make it so that when the formData already had values
  // (for example when editing profile), it shows the right things in the inputs already

  return (
    <form className={`form flex flex-col space-y-4 ${modifiers.includes("no-spacing") ? "!space-y-0" : ""} ${className}`} onSubmit={action}>
      {content?.map((input, i) => {
        return inputByType(formData, setFormData, input, i);
      })}
      <div className={`flex ${modifiers.includes("centered") ? "justify-center" : "justify-end space-x-2"} ${modifiers.includes("button-full") ? "w-full" : ""}`}>
        <Button
          modifiers={`${modifiers.includes("buttons-w-full") ? "" : modifiers.includes("buttons-w-3/4") ? "width-3/4" : "width-xs"} ${type === "login" ? "arrow" : ""}`}
          label={type === "login" ? t("login") : buttonLabel ? buttonLabel : t("submit")}
        />
      </div>
    </form>
  );
};

export default Form;
