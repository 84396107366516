import { useContext } from "react";
import { useTranslation } from "react-i18next";

import useGeneral from "./useGeneral";
import { SelectedCompanyContext } from "../contexts/SelectedCompanyContext";

const useServiceOffers = () => {
  const { t } = useTranslation();

  const { formatCurrency } = useGeneral();
  const selectedCompany = useContext(SelectedCompanyContext);

  const companyIsUser = (offer) => {
    return offer.companyId === selectedCompany.id;
  };

  const getStatusColor = (status) => {
    const STATUS_COLORS = {
      approved: "green",
      pending: "yellow",
      denied: "red",
      other: "default"
    };

    return STATUS_COLORS[status];
  };

  const showPrice = (offer) => {
    let price = "";

    switch (offer.price.type) {
      default:
      case "fixed":
        price = formatCurrency(offer.price.fixed);
        break;

      case "range":
        price = `${formatCurrency(offer.price.range[0])} — ${formatCurrency(offer.price.range[1])}`;
        break;

      case "estimate":
        const estimatePrice = offer.price.estimate !== 0 ? formatCurrency(offer.price.estimate) : t("free");
        price = t(companyIsUser(offer) ? "myEstimate" : "estimate", { price: estimatePrice });
        break;
    }

    return offer.price.type === "estimate" && companyIsUser(offer) ? price : t("price", { price: price });
  };

  return { companyIsUser, getStatusColor, showPrice };
};

export default useServiceOffers;
