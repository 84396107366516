import React from "react";
import Avatar from "../../avatar/Avatar";
import { IconContext } from "react-icons";
import PopupButton from "../../popup/PopupButton";

const ProfilePopupButton = ({ type, image, imageAlt, text, link, action, companyId, className, textColor = "text-white dark:text-white" }) => {
  const content = (
    <div className="flex items-center space-x-2">
      {type === "icon" && <div className="flex-none w-8 h-8">{<IconContext.Provider value={{ className: "w-full h-full" }}>{image}</IconContext.Provider>}</div>}
      {type === "avatar" && (
        <div className="flex-none w-8 h-8">
          <Avatar image={image} alt={imageAlt} />
        </div>
      )}
      <p className="text-nowrap text-ellipsis overflow-hidden">{text}</p>
    </div>
  );

  if (link) {
    return (
      <PopupButton textColor={textColor} hoverBackground="hover:bg-neutral-700 hover:dark:bg-neutral-800" link={link} className={className}>
        {content}
      </PopupButton>
    );
  }

  if (type === "avatar") {
    return (
      <PopupButton
        textColor={textColor}
        hoverBackground="hover:bg-neutral-700 hover:dark:bg-neutral-800"
        action={() => {
          action(companyId);
        }}
        className={className}
      >
        {content}
      </PopupButton>
    );
  }

  return (
    <PopupButton textColor={textColor} hoverBackground="hover:bg-neutral-700 hover:dark:bg-neutral-800" action={action} className={className}>
      {content}
    </PopupButton>
  );
};

export default ProfilePopupButton;
