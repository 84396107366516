import React from "react";
import { IconContext } from "react-icons";

import { MdArrowForward } from "react-icons/md";

import { TERipple } from "tw-elements-react";

const Button = ({
  action,
  link,
  label,
  disabled,
  background = "bg-hoptop-500 dark:bg-hoptop-600 hover:bg-hoptop-400 hover:dark:bg-hoptop-500",
  text = "text-white dark:text-white",
  className,
  modifiers = ""
}) => {
  const CLASSES = `w-full h-full border-none rounded-lg shadow-lg overflow-hidden cursor-pointer transition-all hover:shadow-xl active:shadow-2xl ${background} ${text} ${
    disabled ? "opacity-50 pointer-events-none" : ""
  } ${modifiers.includes("reverse") && "!bg-transparent !border-solid !border-2 !border-white hover:!bg-white hover:!bg-opacity-25 hover:!shadow-none active:!shadow-none"} ${
    modifiers.includes("no-shadow") && "!shadow-none active:!shadow-none"
  } ${modifiers.includes("round") && "!rounded-full"} ${className}`;

  const content = modifiers.includes("arrow") ? (
    <div className="relative w-full h-full inline-flex items-center justify-center overflow-hidden transition duration-300 ease-out group">
      <span className={`absolute inset-0 flex items-center justify-center w-full h-full duration-300 -translate-x-full group-hover:translate-x-0 ease ${text}`}>
        <IconContext.Provider value={{ className: "w-6 h-6" }}>
          <MdArrowForward />
        </IconContext.Provider>
      </span>
      <span className={`absolute flex items-center justify-center w-full h-full transition-all duration-300 transform group-hover:translate-x-full ease ${background} ${text}`}>
        {label}
      </span>
    </div>
  ) : (
    label
  );

  return (
    <div
      className={`relative my-2 w-full h-9 ${modifiers.includes("width-2xs") && "!max-w-2xs"} ${modifiers.includes("width-xs") && "!max-w-xs"} ${
        modifiers.includes("width-sm") && "!max-w-sm"
      } ${modifiers.includes("width-md") && "!max-w-md"} ${modifiers.includes("width-lg") && "!max-w-lg"} ${modifiers.includes("width-xl") && "!max-w-xl"} ${
        modifiers.includes("width-1/4") && "!max-w-1/4"
      } ${modifiers.includes("width-1/2") && "!max-w-1/2"} ${modifiers.includes("width-3/4") && "!max-w-3/4"} ${modifiers.includes("height-lg") && "!h-12 !text-lg !font-bold"} ${
        modifiers.includes("no-margin") && "!my-0"
      } ${modifiers.includes("no-top-margin") && "!mt-0"} ${modifiers.includes("no-bottom-margin") && "!mb-0"}`}
    >
      <TERipple rippleColor="light" rippleRadius={disabled ? "0" : ""} className={`w-full h-full`}>
        {link ? (
          <a href={link} className={`block text-center content-center ${CLASSES}`}>
            {content}
          </a>
        ) : action ? (
          <button onClick={action} className={CLASSES}>
            {content}
          </button>
        ) : (
          <button className={CLASSES}>{content}</button>
        )}
      </TERipple>
    </div>
  );
};

export default Button;
