/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { MdManageSearch, MdOutlineContentPasteGo, MdAddBox, MdOutlineReadMore, MdSubscriptions, MdSettings } from 'react-icons/md';
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import useImagePreloader from "../../../hooks/useImagePreloader";
import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import TempImage from "../../../images/temp/dashboardaccueilimage.png";

import Card from "../../../components/card/Card";
import ConversationPreview from "../../../components/chat/elements/ConversationPreview";
import ConversationPreviewList from "../../../components/chat/ConversationPreviewList";
import TendersListHome from "../../../components/tenders/TendersListHome";

const preloadSrcList = [TempImage];

const DashboardHome = () => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const rightSideRef = useRef(null);

  const { fullName, companies, isCompany } = useAuth();
  const { showResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollIsTop, setScrollIsTop] = useState(true);
  const [scrollIsBottom, setScrollIsBottom] = useState(false);

  const [dashboardData, setDashboardData] = useState({
    // Users
    pendingTenders: "-",
    newServiceOffers: "-",

    // Companies
    newTenders: "-",
    pendingServiceOffers: "-",

    // Both
    activeConversations: "-",
    recentConversations: [],
    recentTenders: []
  });

  const getDashboardUser = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `users/current-user/dashboard`, { withCredentials: true });
      setDashboardData(response.data);
    } catch (e) {
      showResponse(e);
    }
  };

  const getDashboardCompany = async () => {
    const selectedCompanyId = companies()[parseInt(localStorage.getItem("company"))]._id;

    try {
      if (selectedCompanyId) {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `companies/${selectedCompanyId}/dashboard`, { withCredentials: true });
        setDashboardData(response.data);
      }
    } catch (e) {
      showResponse(e);
    }
  };

  useEffect(() => {
    const checkScroll = (e) => {
      setScrollTop(e.target.scrollTop);
      setScrollIsBottom(Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= 3);
    };

    if (rightSideRef.current) {
      setScrollTop(rightSideRef.current.scrollTop);
      setScrollIsBottom(Math.abs(rightSideRef.current.scrollHeight - rightSideRef.current.scrollTop - rightSideRef.current.clientHeight) <= 3);

      window.addEventListener("resize", checkScroll);
      rightSideRef.current.addEventListener("scroll", checkScroll);

      return () => {
        window.removeEventListener("resize", checkScroll);
        if (rightSideRef.current) {
          rightSideRef.current.removeEventListener("scroll", checkScroll);
        }
      };
    }
  }, [scrollTop]);

  useEffect(() => {
    setScrollIsTop(scrollTop === 0);
  }, [scrollTop]);

  useEffect(() => {
    if (!isCompany()) {
      getDashboardUser();
    } else if (isCompany() && selectedCompany.isNull !== null) {
      getDashboardCompany();
    }
  }, [selectedCompany]);

  return (
    <div ref={rightSideRef} className="flex h-[calc(100svh-3rem)] space-x-4 font-rubik">
      <div className="flex-1/4 flex flex-col space-y-4">
        <Card className="shadow-xl shadow-red-600/40 dark:shadow-red-600/35">
          <div className="text-justify mb-6">
            <h1 className="text-[19px] font-semibold text-black dark:text-white">
              {t("welcome", { name: fullName() })}
            </h1>
            <p className="text-sm text-gray-600 dark:text-white">
  {isCompany() ? (
    <>
      {t("dashboardLoggedInAsCompany")} {companies()[parseInt(localStorage.getItem("company"))].displayName}
    </>
  ) : (
    t("dashboardLoggedInAsIndividual")
  )}
</p>
          </div>
          
          <div className="grid grid-cols-2 gap-6">
            {isCompany() ? (
              <>
                <Link to="/dashboard/tenders/browse" className="flex flex-col items-center space-y-2 text-black dark:text-white hover:underline">
                  <MdManageSearch className="text-3xl text-black dark:text-white" />
                  <p className="text-sm text-center">{t("dashboardAllTenders")}</p>
                </Link>
                <Link to={`/dashboard/service-offers`} className="flex flex-col items-center space-y-2 text-black dark:text-white hover:underline">
                  <MdOutlineContentPasteGo className="text-3xl text-black dark:text-white" />
                  <p className="text-sm text-center">{t("dashboardMyServiceOffers")}</p>
                </Link>
              </>
            ) : (
              <>
                <Link to="/dashboard/tenders/create" className="flex flex-col items-center space-y-2 text-black dark:text-white hover:underline">
                  <MdAddBox className="text-3xl text-black dark:text-white" />
                  <p className="text-sm text-center">{t("dashboardCreateTender")}</p>
                </Link>
                <Link to="/dashboard/tenders" className="flex flex-col items-center space-y-2 text-black dark:text-white hover:underline">
                  <MdOutlineReadMore className="text-3xl text-black dark:text-white" />
                  <p className="text-sm text-center">{t("dashboardMyTenders")}</p>
                </Link>
              </>
            )}
          </div>
          <div className="grid grid-cols-2 gap-6 mt-6">
            <Link to="/dashboard/settings" className="flex flex-col items-center space-y-2 text-black dark:text-white hover:underline">
              <MdSettings className="text-3xl text-black dark:text-white" />
              <p className="text-sm">{t("dashboardSettings")}</p>
            </Link>
            <Link to="/dashboard/settings/subscription" className="flex flex-col items-center space-y-2 text-black dark:text-white hover:underline">
              <MdSubscriptions className="text-3xl text-black dark:text-white" />
              <p className="text-sm">{t("dashboardSubscription")}</p>
            </Link>
          </div>
        </Card>
        <ConversationPreviewList type="dashboardHome">
          {dashboardData.recentConversations.length > 0 ? (
            dashboardData.recentConversations.map((preview) => <ConversationPreview key={preview._id} preview={preview} link="/dashboard/messages" />)
          ) : (
            <p>{t("loading")}</p>
          )}
        </ConversationPreviewList>
      </div>

      <div
        ref={rightSideRef}
        className={`flex-3/4 flex flex-col mr-2 pr-2 space-y-4 overflow-y-scroll scrollbar-preset-thin ${
          !scrollIsTop && !scrollIsBottom ? "top-bottom-blur-mask" : !scrollIsTop ? "top-blur-mask" : !scrollIsBottom ? "bottom-blur-mask" : ""
        }`}
      >
        <div className="flex-1/10">
          <div className="flex items-center justify-center text-center space-x-4 text-black dark:text-white">
            {isCompany() ? (
              <>
                <Card modifiers="title-sm" className="shadow-xl shadow-red-600/50 dark:shadow-red-600/35">
                  <CountdownItem num={dashboardData.newTenders} text={t("dashboardStatsNewTendersToday")} />
                </Card>
                <Card modifiers="title-sm" className="shadow-xl shadow-red-600/40 dark:shadow-red-600/35">
                  <CountdownItem num={dashboardData.pendingServiceOffers} text={t("dashboardStatsPendingServiceOffers")} />
                </Card>
                <Card modifiers="title-sm" className="shadow-xl shadow-red-600/40 dark:shadow-red-600/35">
                  <CountdownItem num={dashboardData.activeConversations} text={t("dashboardStatsActiveConversations")} />
                </Card>
              </>
            ) : (
              <>
                <Card modifiers="title-sm" className="shadow-xl shadow-red-600/40 dark:shadow-red-600/35">
                  <CountdownItem num={dashboardData.pendingTenders} text={t("dashboardStatsPendingTenders")} />
                </Card>
                <Card modifiers="title-sm" className="shadow-xl shadow-red-600/40 dark:shadow-red-600/35">
                  <CountdownItem num={dashboardData.newServiceOffers} text={t("dashboardStatsPendingServiceOffers")} />
                </Card>
                <Card modifiers="sm" className="shadow-xl shadow-red-600/40 dark:shadow-red-600/35">
                  <CountdownItem num={dashboardData.activeConversations} text={t("dashboardStatsActiveConversations")} />
                </Card>
              </>
            )}
          </div>
        </div>
        <div className="flex-9/10">
          <Card title={isCompany() ? t("dashboardAllTenders") : t("dashboardMyTenders")} modifiers="title-lg" className={"h-full shadow-xl rounded-sm shadow-red-600/40 dark:shadow-red-600/35"} >
            <TendersListHome type="myTenders" tenders={dashboardData.recentTenders} />
          </Card>
        </div>
      </div>
    </div>
  );
};

const CountdownItem = ({ num, text }) => {
  return (
    <div className=" h-1 md:h-24 flex flex-col gap-1 md:gap-2 items-center justify-center border-slate-200">
      <div className="w-full text-center relative overflow-hidden">
        <span className="block text-xl md:text-2xl lg:text-3xl xl:text-4xl font-mono text-black dark:text-white font-medium">{num}</span>
      </div>
      <span className="text-xs font-rubik md:text-sm lg:text-base font-light text-black text-center dark:text-white">{text}</span>
    </div>
  );
};

export default DashboardHome;
