/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

import usePlaces from "../../../hooks/usePlaces";

const CoordsLoader = ({ address, setCoords }) => {
  const { isAddressObjectEmpty, getAddressStringFromObject, getCoordsFromGeocodeResult } = usePlaces();

  const geocoding = useMapsLibrary("geocoding");
  const [geocoder, setGeocoder] = useState(null);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const selectAddress = (addressString) => {
    geocoder.geocode({ address: addressString }, (result) => {
      setCoords(getCoordsFromGeocodeResult(result));
    });
  };

  useEffect(() => {
    if (geocoder && !isAddressObjectEmpty(address) && isFirstLoad) {
      selectAddress(getAddressStringFromObject(address), false);
      setIsFirstLoad(false);
    }
  }, [address, geocoder]);

  useEffect(() => {
    if (geocoding) {
      setGeocoder(new geocoding.Geocoder());
    }
  }, [geocoding]);

  return <></>;
};

export default CoordsLoader;
