/* eslint-disable no-loop-func */

import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { IconContext } from "react-icons";

import useAuth from "../../../hooks/useAuth";
import useLogger from "../../../hooks/useLogger";
import { UserSocketContext } from "../../../contexts/UserSocketContext";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import { MdSend } from "react-icons/md";

const MessageInput = ({ conversation }) => {
  const { userId, isCompany } = useAuth();
  const selectedCompany = useContext(SelectedCompanyContext);
  const { showResponse } = useLogger();
  const userSocket = useContext(UserSocketContext);

  const inputRef = useRef(null);

  const [value, setValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const onChange = (event) => {
    event.target.style.height = "";
    event.target.style.height = event.target.scrollHeight + "px";

    if (isTyping) {
      clearTimeout(isTyping);
    } else {
      userSocket.socket.emit("typing", {
        conversationId: conversation._id,
        authorId: isCompany() && !selectedCompany.isNull ? selectedCompany.id : userId(),
        status: true
      });
    }

    setIsTyping(setTimeout(stopTyping, 5000));
    setValue(event.target.value);
  };

  const stopTyping = async (event) => {
    setIsTyping(false);
    userSocket.socket.emit("typing", {
      conversationId: conversation._id,
      authorId: isCompany() && !selectedCompany.isNull ? selectedCompany.id : userId(),
      status: false
    });
  };

  const send = async (event) => {
    if ((!event.shiftKey && event.keyCode === 13) || !event.keyCode) {
      event.preventDefault();

      if (value.trim() !== "") {
        let attempts = 0;
        let success = false;
        let message = value;
        do {
          setValue("");
          await axios
            .post(process.env.REACT_APP_BACKEND_URL + "conversations/send/" + conversation._id, { text: value }, { withCredentials: true, contentType: "multipart/form-data" })
            .then((response) => {
              stopTyping();
              success = true;

              const message = response.data;
              userSocket.socket.emit("messageSent", {
                conversationId: conversation._id,
                messageId: message.id,
                text: value,
                author: message.author,
                createdAt: message.timestamp
              });
            })
            .catch(async (e) => {
              if (++attempts === 3) {
                setValue(message);
                showResponse(e);
              }
            });
        } while (success === false && attempts < 3);
      }

      inputRef.current.style.height = "";
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [conversation]);

  return (
    <div className="flex items-center justify-between bg-neutral-200 dark:bg-neutral-600 w-full min-h-12 max-h-52 rounded-lg">
      <textarea
        ref={inputRef}
        value={value}
        onChange={onChange}
        onKeyDown={send}
        name="messageInput"
        id="messageInput"
        placeholder={`Message ${conversation.recipient.name}`}
        rows="1"
        className="flex-1 bg-transparent max-h-52 p-3 outline-none resize-none scrollbar-preset-thin"
        autoFocus
      />
      <div className="h-full">
        <button className="h-full p-3" onClick={send}>
          <IconContext.Provider value={{ className: "w-full h-6" }}>
            <MdSend />
          </IconContext.Provider>
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
