import { createContext } from "react";

export const SelectedCompanyContext = createContext({
  isNull: null,
  id: null,
  name: null,
  logo: null,
  subdomains: null,
  setCompany: () => {}
});
