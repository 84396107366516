import ImageFonc1 from "../../images/temp/fonc1.jpg";
import ImageFonc2 from "../../images/temp/fonc2.jpg";
import ImageFonc3 from "../../images/temp/fonc3.jpg";
import ImageFonc4 from "../../images/temp/fonc4.jpg";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Fonctionnalite = () => {
  const { t } = useTranslation();

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.1 });

  const [hasBeenViewed1, setHasBeenViewed1] = useState(false);
  const [hasBeenViewed2, setHasBeenViewed2] = useState(false);
  const [hasBeenViewed3, setHasBeenViewed3] = useState(false);
  const [hasBeenViewed4, setHasBeenViewed4] = useState(false);

  useEffect(() => {
    if (inView1 && !hasBeenViewed1) setHasBeenViewed1(true);
  }, [inView1, hasBeenViewed1]);

  useEffect(() => {
    if (inView2 && !hasBeenViewed2) setHasBeenViewed2(true);
  }, [inView2, hasBeenViewed2]);

  useEffect(() => {
    if (inView3 && !hasBeenViewed3) setHasBeenViewed3(true);
  }, [inView3, hasBeenViewed3]);

  useEffect(() => {
    if (inView4 && !hasBeenViewed4) setHasBeenViewed4(true);
  }, [inView4, hasBeenViewed4]);

  const features = [
    { image: ImageFonc1, title: t("features1Title"), text: t("features1Text"), ref: ref1, hasBeenViewed: hasBeenViewed1, reverse: false },
    { image: ImageFonc2, title: t("features2Title"), text: t("features2Text"), ref: ref2, hasBeenViewed: hasBeenViewed2, reverse: true },
    { image: ImageFonc3, title: t("features3Title"), text: t("features3Text"), ref: ref3, hasBeenViewed: hasBeenViewed3, reverse: false },
    { image: ImageFonc4, title: t("features4Title"), text: t("features4Text"), ref: ref4, hasBeenViewed: hasBeenViewed4, reverse: true },
  ];

  return (
    <section>
      <div className="mx-auto max-w-screen-2xl px-4 py-2 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
        <div className="text-center mb-28 text-xl mx-auto max-w-xl">
          <h2 className="text-4xl">{t("featuresTitle")}</h2>
          <br />
          <p>{t("featuresDescription")}</p>
        </div>
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className={`grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 ${index > 0 ? 'mt-36' : ''}`}
            ref={feature.ref}
            initial="hidden"
            animate={feature.hasBeenViewed ? "visible" : "hidden"}
            variants={sectionVariants}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <div className={`relative h-64 overflow-hidden rounded-lg sm:h-80 lg:h-full ${feature.reverse ? 'lg:order-last' : ''}`}>
              <img
                alt="bureau"
                src={feature.image}
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
            <div className="lg:py-24">
              <h2 className="text-3xl font-bold sm:text-4xl">{feature.title}</h2>
              <p className="mt-4 text-gray-600 dark:text-white">{feature.text}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Fonctionnalite;
