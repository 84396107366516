/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import axios from "axios";

import useAuth from "../../hooks/useAuth";
import useLogger from "../../hooks/useLogger";

const useSettingsFormData = () => {
  const { companies } = useAuth();
  const { showResponse } = useLogger();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [user, setUser] = useState(null);
  const [theme, setTheme] = useState("");
  const [userNotifs, setUserNotifs] = useState(null);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanyNotifs, setSelectedCompanyNotifs] = useState(null);

  const [formData, setFormData] = useState({
    accountInfoFirstName: "",
    accountInfoLastName: "",
    accountInfoEmail: "",
    accountInfoPhone: "",
    accountAddressMapInput: {
      address: "",
      apt: "",
      city: "",
      province: "",
      country: "",
      postalCode: ""
    },
    accountPasswordCurrentInput: "",
    accountPasswordNewInput: "",
    accountPasswordNewConfirmInput: "",

    companiesPreferredDropdown: "",
    companiesSelectedDropdown: "",
    companiesInfoName: "",
    companiesInfoEmail: "",
    companiesInfoPhone: "",
    companiesInfoOperationRange: "",
    companiesSubdomainsCheckboxes: [],
    companiesAddressMapInput: {
      address: "",
      apt: "",
      city: "",
      province: "",
      country: "",
      postalCode: ""
    },

    themeSwitcherDropdown: "",
    languageSwitcherDropdown: "",

    userNotificationsLoginGroup: [],
    userNotificationsServiceOfferGroup: [],
    userNotificationsConversationGroup: [],
    userNotificationsMessageGroup: [],

    companyNotificationsSelectedDropdown: "",
    companyNotificationsApprovedGroup: [],
    companyNotificationsTenderGroup: [],
    companyNotificationsReviewGroup: [],
    companyNotificationsConversationGroup: [],
    companyNotificationsMessageGroup: [],

    mfaPreferredDropdown: "",
    mfaAuthenticatorIsSetup: false,
    mfaSMSIsSetup: false,
    mfaEmailIsSetup: false,
    mfaCodesIsSetup: false
  });

  const getUser = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "users/current-user", { withCredentials: true })
      .then((response) => {
        setUser(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const getTheme = () => {
    setTheme(localStorage.getItem("theme"));
  };

  const getUserNotifs = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "users/current-user/notification-preferences", { withCredentials: true })
      .then((response) => {
        setUserNotifs(response.data.notifications);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const getSelectedCompany = async (id) => {
    if (id) {
      await axios
        .get(process.env.REACT_APP_BACKEND_URL + `companies/${id}`, { withCredentials: true })
        .then((response) => {
          setSelectedCompany(response.data);
        })
        .catch((e) => {
          showResponse(e);
        });
    } else {
      setSelectedCompany(null);
    }
  };

  const getSelectedCompanyNotifs = async (id) => {
    if (id) {
      await axios
        .get(process.env.REACT_APP_BACKEND_URL + `companies/${id}/notification-preferences`, { withCredentials: true })
        .then((response) => {
          setSelectedCompanyNotifs({ _id: id, ...response.data.notifications });
        })
        .catch((e) => {
          showResponse(e);
        });
    } else {
      setSelectedCompanyNotifs(null);
    }
  };

  const convertNotificationGroup = (value) => {
    if (value.sms && value.email) {
      return ["sms", "email"];
    } else if (value.sms) {
      return ["sms"];
    } else if (value.email) {
      return ["email"];
    }

    return [];
  };

  const convertMFAPreferredMethod = (value) => {
    if (value === "nothing") {
      return "";
    }

    return value;
  };

  useEffect(() => {
    getUser();
    getTheme();
    getUserNotifs();
  }, []);

  useEffect(() => {
    if (companies()) {
      getSelectedCompany(formData.companiesSelectedDropdown);
    }
  }, [formData.companiesSelectedDropdown]);

  useEffect(() => {
    if (companies()) {
      getSelectedCompanyNotifs(formData.companyNotificationsSelectedDropdown);
    }
  }, [formData.companyNotificationsSelectedDropdown]);

  useEffect(() => {
    if (isFirstLoad && user && theme !== "" && userNotifs) {
      const selectedCompanyIndex = localStorage.getItem("settingsSelectedCompany"),
        selectedCompanyNotifsIndex = localStorage.getItem("settingsSelectedCompanyNotifs");
      let selectedCompanyId, selectedCompanyNotifsId;

      if (companies()) {
        selectedCompanyId = companies()[parseInt(selectedCompanyIndex)]?._id;
        selectedCompanyNotifsId = companies()[parseInt(selectedCompanyNotifsIndex)]?._id;
      }

      setFormData({
        ...formData,

        accountInfoFirstName: user.firstName,
        accountInfoLastName: user.lastName,
        accountInfoEmail: user.email,
        accountInfoPhone: user.phone,
        accountAddressMapInput: {
          ...user.addr,
          apt: user.addr.apt ? user.addr.apt : ""
        },

        companiesPreferredDropdown: companies() ? companies()[user.preferredCompany]._id : "",
        companiesSelectedDropdown: selectedCompanyIndex ? selectedCompanyId : "",

        themeSwitcherDropdown: theme,
        languageSwitcherDropdown: user.preferredLanguage,

        userNotificationsLoginGroup: convertNotificationGroup(userNotifs.login),
        userNotificationsServiceOfferGroup: convertNotificationGroup(userNotifs.newServiceOffer),
        userNotificationsConversationGroup: convertNotificationGroup(userNotifs.newConversation),
        userNotificationsMessageGroup: convertNotificationGroup(userNotifs.newMessage),

        companyNotificationsSelectedDropdown: selectedCompanyNotifsIndex ? selectedCompanyNotifsId : "",

        mfaPreferredDropdown: convertMFAPreferredMethod(user.mfa.preferredLoginMethod),
        mfaAuthenticatorIsSetup: user.mfa.secretToken,
        mfaPhoneIsSetup: user.mfa.phone,
        mfaEmailIsSetup: user.mfa.email,
        mfaCodesIsSetup: user.mfa.recoveryCodes
      });

      setIsFirstLoad(false);
    }
  }, [user, theme, userNotifs]);

  useEffect(() => {
    if (selectedCompany) {
      localStorage.setItem(
        "settingsSelectedCompany",
        companies().findIndex((company) => {
          return company._id === selectedCompany._id;
        })
      );

      setFormData({
        ...formData,

        companiesInfoName: selectedCompany.displayName,
        companiesInfoEmail: selectedCompany.email,
        companiesInfoPhone: selectedCompany.phone,
        companiesInfoOperationRange: selectedCompany.range,
        companiesSubdomainsCheckboxes: selectedCompany.subdomains,
        companiesAddressMapInput: {
          ...selectedCompany.addr,
          apt: selectedCompany.addr.apt ? selectedCompany.addr.apt : ""
        }
      });
    } else {
      if (!isFirstLoad) {
        localStorage.removeItem("settingsSelectedCompany");
      }

      setFormData({
        ...formData,

        companiesInfoName: "",
        companiesInfoEmail: "",
        companiesInfoPhone: "",
        companiesInfoOperationRange: "",
        companiesSubdomainsCheckboxes: "",
        companiesAddressMapInput: {
          address: "",
          apt: "",
          city: "",
          province: "",
          country: "",
          postalCode: ""
        }
      });
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompanyNotifs) {
      localStorage.setItem(
        "settingsSelectedCompanyNotifs",
        companies().findIndex((company) => {
          return company._id === selectedCompanyNotifs._id;
        })
      );

      setFormData({
        ...formData,

        companyNotificationsApprovedGroup: convertNotificationGroup(selectedCompanyNotifs.approvedServiceOffer),
        companyNotificationsTenderGroup: convertNotificationGroup(selectedCompanyNotifs.newTender),
        companyNotificationsReviewGroup: convertNotificationGroup(selectedCompanyNotifs.newReview),
        companyNotificationsConversationGroup: convertNotificationGroup(selectedCompanyNotifs.newConversation),
        companyNotificationsMessageGroup: convertNotificationGroup(selectedCompanyNotifs.newMessage)
      });
    } else {
      if (!isFirstLoad) {
        localStorage.removeItem("settingsSelectedCompanyNotifs");
      }

      setFormData({
        ...formData,

        companyNotificationsApprovedGroup: [],
        companyNotificationsTenderGroup: [],
        companyNotificationsReviewGroup: [],
        companyNotificationsConversationGroup: [],
        companyNotificationsMessageGroup: []
      });
    }
  }, [selectedCompanyNotifs]);

  return [formData, setFormData];
};

export default useSettingsFormData;
