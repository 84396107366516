/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import useLogger from "../../hooks/useLogger";
import { SelectedCompanyContext } from "../../contexts/SelectedCompanyContext";

import Form from "../../components/forms/Form";
import Card from "../../components/card/Card";
import SmallServiceOffersList from "./SmallServiceOffersList";
import MyServiceOffer from "./MyServiceOffer";

const ServiceOffersDisplay = ({ tender, serviceOffers, setServiceOffers, myServiceOffer, setMyServiceOffer, serviceOffersExpanded }) => {
  const { t } = useTranslation();

  const serviceOffersRef = useRef(null);

  const { showResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollIsTop, setScrollIsTop] = useState(true);
  const [scrollIsBottom, setScrollIsBottom] = useState(false);
  const [alreadySentOffer, setAlreadySentOffer] = useState();

  const [formData, setFormData] = useState({
    message: "",
    priceType: "",
    priceFixed: "",
    priceRange: ["", ""],
    priceEstimate: "",
    date: {
      start: "",
      end: ""
    }
  });

  const formContent = [
    { type: "textarea", id: "message", label: t("message"), required: true },
    {
      type: "dropdown",
      id: "priceType",
      name: "priceType",
      label: t("priceType"),
      required: true,
      items: [
        { name: "fixed", label: t("backend.enums.priceTypes.fixed") },
        { name: "range", label: t("backend.enums.priceTypes.range") },
        { name: "estimate", label: t("backend.enums.priceTypes.estimate") }
      ]
    },
    {
      type: "num",
      id: "priceFixed",
      label: t("priceFixed"),
      required: formData.priceType === "fixed",
      hidden: formData.priceType !== "fixed"
    },
    {
      type: "num",
      id: "priceRange[0]",
      label: t("priceRange.start"),
      required: formData.priceType === "range",
      hidden: formData.priceType !== "range"
    },
    {
      type: "num",
      id: "priceRange[1]",
      label: t("priceRange.end"),
      required: formData.priceType === "range",
      hidden: formData.priceType !== "range"
    },
    {
      type: "num",
      id: "priceEstimate",
      label: t("priceEstimate"),
      hidden: formData.priceType !== "estimate"
    },
    { type: "date", id: "date.start", name: "date.start", label: t("startDate"), required: true },
    { type: "date", id: "date.end", name: "date.end", label: t("endDate"), required: true }
  ];

  const getValueFromPriceType = (formData) => {
    let value;

    switch (formData.priceType) {
      case "fixed":
        value = formData.priceFixed;
        break;

      case "range":
        value = formData.priceRange;
        break;

      case "estimate":
        value = formData.priceEstimate !== "" ? formData.priceEstimate : 0;
        break;

      default:
        break;
    }

    return value;
  };

  const getServiceOffers = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `service-offers/${tender._id}`, { withCredentials: true })
      .then((response) => {
        setServiceOffers(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const getMyServiceOffer = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `service-offers/${tender._id}/from/${selectedCompany.id}`, { withCredentials: true })
      .then((response) => {
        if (response.data.hasServiceOffer) {
          setMyServiceOffer(response.data.serviceOffer);
          setAlreadySentOffer(true);
        } else {
          setAlreadySentOffer(false);
        }
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const submitServiceOffer = async () => {
    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + `service-offers/send/${tender._id}/${selectedCompany.id}`,
        {
          message: formData.message,
          priceType: formData.priceType,
          value: getValueFromPriceType(formData),
          date: formData.date
        },
        { withCredentials: true }
      )
      .then((response) => {
        showResponse(response);

        getMyServiceOffer();
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    const checkScroll = (e) => {
      setScrollTop(e.target.scrollTop);
      setScrollIsBottom(Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 3));
    };

    // FIXME Along with DashboardTender.jsx, DashboardHome.jsx & SettingContent.jsx, scrollHeight is not the right thing when loading (Issue #9)
    setScrollTop(serviceOffersRef.current.scrollTop);
    setScrollIsBottom(Math.abs(serviceOffersRef.current.scrollHeight - serviceOffersRef.current.scrollTop - serviceOffersRef.current.clientHeight <= 3));

    window.addEventListener("resize", checkScroll);
    serviceOffersRef.current.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
      serviceOffersRef.current.removeEventListener("scroll", checkScroll);
    };
  }, [scrollTop]);

  useEffect(() => {
    setScrollIsTop(scrollTop === 0);
  }, [scrollTop]);

  useEffect(() => {
    if (tender) {
      if (tender.loggedUserIsAuthor) {
        getServiceOffers();
      } else {
        getMyServiceOffer();
      }
    }
  }, [tender]);

  return (
    <div
      ref={serviceOffersRef}
      className={`flex flex-col flex-2/6 w-[27rem] h-[calc(100svh-3rem)] px-2 space-y-4 overflow-y-scroll scrollbar-preset-thin transition-[width,padding] duration-700 ${
        tender?.loggedUserIsAuthor && !scrollIsTop && !scrollIsBottom
          ? "top-bottom-blur-mask"
          : tender?.loggedUserIsAuthor && !scrollIsTop
          ? "top-blur-mask"
          : tender?.loggedUserIsAuthor && !scrollIsBottom
          ? "bottom-blur-mask"
          : ""
      } ${!serviceOffersExpanded ? "!w-0 !p-0 !overflow-clip" : ""}`}
    >
      {tender ? (
        tender.loggedUserIsAuthor ? (
          <SmallServiceOffersList serviceOffers={serviceOffers} refreshServiceOffers={getServiceOffers} />
        ) : alreadySentOffer !== undefined ? (
          alreadySentOffer ? (
            <MyServiceOffer offer={myServiceOffer} />
          ) : (
            (tender.status === "open" || tender.status === "abandoned") && (
              <Card title={t("submitServiceOffer")}>
                <Form
                  content={formContent}
                  formData={formData}
                  setFormData={setFormData}
                  action={(event) => {
                    event.preventDefault();

                    submitServiceOffer();
                  }}
                />
              </Card>
            )
          )
        ) : (
          <div>{t("loading")}</div>
        )
      ) : (
        <div>{t("loading")}</div>
      )}
    </div>
  );
};

export default ServiceOffersDisplay;
