import React, { useRef, useState } from "react";

import { TERipple } from "tw-elements-react";

const Toggle = ({ formData, setFormData, name, from, className, id, required, disabled, label }) => {
  const inputRef = useRef(null),
    rippleRef = useRef(null);

  const checked = from ? formData[from].includes(name) : formData[name];
  const newId = from ? from + "-" + id : id;

  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const onChange = (event) => {
    const value = event.target.checked;
    const groupContents = formData[from];

    if (!from) {
      setFormData({
        ...formData,
        [name]: value
      });
    } else {
      if (!groupContents.includes(name)) {
        setFormData({
          ...formData,
          [from]: [...groupContents, name]
        });
      } else {
        setFormData({
          ...formData,
          [from]: [...groupContents.filter((item) => item !== name)]
        });
      }
    }
  };

  const triggerRipple = () => {
    let opts = { view: window, bubbles: true, cancelable: true, buttons: 1 };
    rippleRef.current.dispatchEvent(new MouseEvent("mousedown", opts));
  };

  return (
    <div className={`flex items-center ${className}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <input ref={inputRef} type="checkbox" checked={checked} onChange={onChange} name={name} id={newId} required={required} className={`hidden`} />
      <div
        className={`border-2 w-12 h-7 p-0.5 rounded-full ${!disabled ? "cursor-pointer" : ""} transition-colors ${
          checked
            ? `bg-hoptop-500 border-hoptop-500 ${hovered && !disabled ? "bg-hoptop-600 border-hoptop-600 dark:bg-hoptop-400 dark:border-hoptop-400" : ""} ${
                disabled ? "!bg-hoptop-300 !border-hoptop-300 dark:!bg-hoptop-600 dark:!border-hoptop-600" : ""
              }`
            : `border-2 border-neutral-350 ${hovered && !disabled ? "!border-neutral-500 dark:!border-neutral-100" : ""} ${
                disabled ? "!border-neutral-200 dark:!border-neutral-500" : ""
              }`
        } focus-visible:outline dark:focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-2`}
        onKeyDown={(event) => {
          if (!disabled && event.code === "Space") {
            inputRef.current.click();
            triggerRipple();
          }
        }}
        onClick={() => {
          if (!disabled) {
            inputRef.current.click();
            triggerRipple();
          }
        }}
        tabIndex={0}
      >
        <TERipple
          ref={rippleRef}
          rippleRadius={30}
          rippleDuration={250}
          rippleCentered
          rippleUnbound
          rippleColor={"#AAAAAA"}
          className={`!block w-5 h-5 pointer-events-none transition-[margin] ${checked ? "ml-5" : ""}`}
        >
          <div
            className={`border-2 w-full h-full rounded-full transition-colors ${
              checked
                ? `bg-neutral-100 border-neutral-100 ${disabled ? "!bg-neutral-200 !border-neutral-200 dark:!bg-neutral-400 dark:!border-neutral-400" : ""}`
                : `bg-neutral-350 border-neutral-350 ${hovered && !disabled ? "!bg-neutral-500 !border-neutral-500 dark:!bg-neutral-100 dark:!border-neutral-100" : ""} ${
                    disabled ? "!bg-neutral-200 !border-neutral-200 dark:!bg-neutral-500 dark:!border-neutral-500" : ""
                  }`
            }`}
          />
        </TERipple>
      </div>
      {label && (
        <label htmlFor={newId} className={`ml-1.5 cursor-pointer ${disabled ? "!text-neutral-350 dark:!text-neutral-500 pointer-events-none" : ""}`} onClick={triggerRipple}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Toggle;
