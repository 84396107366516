import { useContext } from "react";

import { SettingsFormDataContext } from "../contexts/SettingsFormDataContext";

const useSettingsVisibility = () => {
  const settingsFormDataContext = useContext(SettingsFormDataContext);

  const mfaAuthenticatorButtonsSetup = () => {
    return !settingsFormDataContext.formData.mfaAuthenticatorIsSetup;
  };

  const mfaAuthenticatorButtonsSetupAgain = () => {
    return settingsFormDataContext.formData.mfaAuthenticatorIsSetup;
  };

  const mfaAuthenticatorButtonsRemove = () => {
    return settingsFormDataContext.formData.mfaAuthenticatorIsSetup;
  };

  const mfaSMSSetup = () => {
    return !settingsFormDataContext.formData.mfaSMSIsSetup;
  };

  const mfaSMSSetupAgain = () => {
    return settingsFormDataContext.formData.mfaSMSIsSetup;
  };

  const mfaSMSRemove = () => {
    return settingsFormDataContext.formData.mfaSMSIsSetup;
  };

  const mfaEmailSetup = () => {
    return !settingsFormDataContext.formData.mfaEmailIsSetup;
  };

  const mfaEmailSetupAgain = () => {
    return settingsFormDataContext.formData.mfaEmailIsSetup;
  };

  const mfaEmailRemove = () => {
    return settingsFormDataContext.formData.mfaEmailIsSetup;
  };

  const mfaCodesSetup = () => {
    return !settingsFormDataContext.formData.mfaCodesIsSetup;
  };

  const mfaCodesSetupAgain = () => {
    return settingsFormDataContext.formData.mfaCodesIsSetup;
  };

  const mfaCodesRemove = () => {
    return settingsFormDataContext.formData.mfaCodesIsSetup;
  };

  return {
    mfaAuthenticatorButtonsSetup,
    mfaAuthenticatorButtonsSetupAgain,
    mfaAuthenticatorButtonsRemove,
    mfaSMSSetup,
    mfaSMSSetupAgain,
    mfaSMSRemove,
    mfaEmailSetup,
    mfaEmailSetupAgain,
    mfaEmailRemove,
    mfaCodesSetup,
    mfaCodesSetupAgain,
    mfaCodesRemove
  };
};

export default useSettingsVisibility;
