import React from "react";

import useConversations from "../../../hooks/useConversations";

import AvatarInfoBox from "../../card/dashboard/AvatarInfoBox";

const ConversationHeader = ({ conversation }) => {
  const { getProfilePic } = useConversations();
  if (conversation) {
    return (
      <>
        <div className="flex items-center p-6 space-x-4">
          <AvatarInfoBox avatar={getProfilePic(conversation.recipient.pfp)} name={conversation.recipient.name} modifiers="space-lg avatar-xl text-2xl" />
        </div>
      </>
    );
  }

  return null;
};

export default ConversationHeader;
