import React from "react";

import DashboardSettings from "../DashboardSettings";

const AccountAddressSettings = () => {
  const CATEGORY_ID = "general";
  const TAB_ID = "account";
  const SUBPAGE_ID = "accountAddress";

  return <DashboardSettings categoryId={CATEGORY_ID} tabId={TAB_ID} subpageId={SUBPAGE_ID} />;
};

export default AccountAddressSettings;
