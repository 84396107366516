import React from "react";
import { IconContext } from "react-icons";

import Feature from "./Feature";

const FeaturesSection = ({ section }) => {
  return (
    <>
      <tr>
        <th className="text-start font-normal px-2 pt-7 pb-5">
          <div className="flex items-center space-x-2">
            <IconContext.Provider
              value={{
                className: "border border-neutral-100 dark:border-neutral-900 bg-neutral-50 dark:bg-neutral-850 w-12 h-12 p-3 rounded-full shadow-md"
              }}
            >
              {section.icon}
            </IconContext.Provider>
            <p className="text-xl">{section.title}</p>
          </div>
        </th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      {section.features.map((feature, i) => {
        return <Feature key={feature.name} section={section} feature={feature} index={i} />;
      })}
    </>
  );
};

export default FeaturesSection;
