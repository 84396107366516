import { createContext } from "react";

export const SelectedConvoContext = createContext({
  conversation: null,
  recipient: null,
  profilePic: null,
  messages: [],
  latestStatus: null,
  addMessage: () => {}
});
