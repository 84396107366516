/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { SettingsContext } from "../../contexts/SettingsContext";

import settings from "../../data/json/settings.json";

import Card from "../card/Card";
import SettingCategory from "./SettingCategory";
import SettingTab from "./SettingTab";

const SettingSidebar = ({ fromSpecificPage = false }) => {
  const { t } = useTranslation();

  const settingsContext = useContext(SettingsContext);

  const sidebarRef = useRef(null);

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollIsTop, setScrollIsTop] = useState(true);
  const [scrollIsBottom, setScrollIsBottom] = useState(false);

  useEffect(() => {
    const checkScroll = (e) => {
      setScrollTop(e.target.scrollTop);
      setScrollIsBottom(Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 3));
    };

    setScrollTop(sidebarRef.current.scrollTop);
    setScrollIsBottom(Math.abs(sidebarRef.current.scrollHeight - sidebarRef.current.scrollTop - sidebarRef.current.clientHeight <= 3));

    window.addEventListener("resize", checkScroll);
    sidebarRef.current.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
      sidebarRef.current.removeEventListener("scroll", checkScroll);
    };
  }, [scrollTop]);

  useEffect(() => {
    setScrollIsTop(scrollTop === 0);
  }, [scrollTop]);

  return (
    <div
      ref={sidebarRef}
      className={`flex-1/4 h-[calc(100svh-3rem)] pr-2 overflow-y-scroll scrollbar-preset-thin ${
        !scrollIsTop && !scrollIsBottom ? "top-bottom-blur-mask" : !scrollIsTop ? "top-blur-mask" : !scrollIsBottom ? "bottom-blur-mask" : ""
      }`}
    >
      <Card className="min-h-full !text-left" modifiers="padding-sm">
        <div className="space-y-6">
          <h2 className="text-xl font-semibold">{t("dashboardSettings")}</h2>
          {settings.map((category) => {
            return (
              <SettingCategory key={category.id} label={t(`settings.categories.${category.id}`)}>
                {category.tabs.map((tab) => {
                  return (
                    <SettingTab
                      key={tab.id}
                      id={tab.id}
                      label={t(`settings.tabs.${tab.id}`)}
                      from={category.id}
                      selectedTabId={settingsContext.selectedTabId}
                      link={tab.type === "page" ? tab.link : undefined}
                      onClick={
                        tab.type === "tab"
                          ? () => {
                              if (!fromSpecificPage) {
                                settingsContext.onTabClick(category.id, tab.id);
                              } else {
                                settingsContext.toLocalStorage(category.id, tab.id);
                                window.location.assign("/dashboard/settings");
                              }
                            }
                          : undefined
                      }
                    />
                  );
                })}
              </SettingCategory>
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default SettingSidebar;
