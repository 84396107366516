import React from "react";

import DashboardSettings from "../DashboardSettings";

const CompaniesAddressSettings = () => {
  const CATEGORY_ID = "general";
  const TAB_ID = "companies";
  const SUBPAGE_ID = "companiesAddress";

  return <DashboardSettings categoryId={CATEGORY_ID} tabId={TAB_ID} subpageId={SUBPAGE_ID} />;
};

export default CompaniesAddressSettings;
