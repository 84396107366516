import React from "react";
import { useTranslation } from "react-i18next";
import BigServiceOfferCard from "../card/dashboard/BigServiceOfferCard";

const BigServiceOffersList = ({ offers }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col-reverse space-y-4 space-y-reverse">
      {offers === null ? (
        <p>{t("loading")}</p>
      ) : offers.length > 0 ? (
        offers.map((offer) => {
          return (
            <BigServiceOfferCard
              key={offer.id}
              offer={offer}
              date={offer.date}
              title={offer.tender.title}
              message={offer.message}
              status={offer.status}
              tenderId={offer.tender._id}
              createdAt={offer.createdAt}
              subdomain={offer.tender.subdomain}
              city={offer.tender.city}
            />
          );
        })
      ) : (
        <p>{t("noMyServiceOffers")}</p>
      )}
    </div>
  );
};

export default BigServiceOffersList;
