import React from "react";

import DashboardSettings from "../DashboardSettings";

const AccountPasswordSettings = () => {
  const CATEGORY_ID = "general";
  const TAB_ID = "account";
  const SUBPAGE_ID = "accountPassword";

  return <DashboardSettings categoryId={CATEGORY_ID} tabId={TAB_ID} subpageId={SUBPAGE_ID} />;
};

export default AccountPasswordSettings;
