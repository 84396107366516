/* eslint-disable react-hooks/exhaustive-deps */

import { useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "./useLogger";
import { StripeContext } from "../contexts/StripeContext";

const useStripe = () => {
  const { t } = useTranslation();

  let tier = localStorage.getItem("subscriptionTier"),
    cycle = localStorage.getItem("subscriptionCycle");

  const { showResponse, confirmAction } = useLogger();
  const stripeContext = useContext(StripeContext);

  const startSubscription = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "subscriptions/subscribe", { tier: tier, contractType: cycle }, { withCredentials: true })
      .then((response) => {
        window.location.assign(response.data.url);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const renewSubscription = async () => {
    await axios
      .patch(
        process.env.REACT_APP_BACKEND_URL + "subscriptions/renew",
        { tier: stripeContext.subscription?.tier, contractType: stripeContext.subscription?.cycle },
        { withCredentials: true }
      )
      .then((response) => {
        stripeContext.getSubscription();

        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const cancelSubscription = async () => {
    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + "subscriptions/cancel", null, { withCredentials: true })
      .then((response) => {
        showResponse(response);

        setTimeout(() => {
          window.location.assign("/dashboard");
        }, 4000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const getSubscription = () => {
    return stripeContext.subscription;
  };

  const getTierAndCycle = () => {
    return { tier, cycle };
  };

  const openSubscriptionPage = () => {
    confirmAction(t("confirmStartSubscription", { tier: t(tier), cycle: t(cycle) }), startSubscription);
  };

  const renewSubscriptionPage = () => {
    confirmAction(t("confirmRenewSubscription", { tier: t(stripeContext.subscription?.tier), cycle: t(stripeContext.subscription?.cycle) }), renewSubscription);
  };

  const cancelMySubscription = () => {
    confirmAction(t("confirmCancelSubscription"), cancelSubscription);
  };

  const restartMySubscription = () => {
    confirmAction(t("confirmRestartSubscription"), renewSubscription);
  };

  return { getSubscription, getTierAndCycle, openSubscriptionPage, renewSubscriptionPage, cancelMySubscription, restartMySubscription };
};

export default useStripe;
