import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import useGeneral from "../../hooks/useGeneral";
import useServiceOffers from "../../hooks/useServiceOffers";
import useLogger from "../../hooks/useLogger";
import { SelectedCompanyContext } from "../../contexts/SelectedCompanyContext";

import Card from "../card/Card";
import TenderInfoBox from "../card/dashboard/TenderInfoBox";
import DateInfoBox from "../card/dashboard/DateInfoBox";
import Button from "../button/Button";
import AvatarInfoBox from "../card/dashboard/AvatarInfoBox";

const MyServiceOffer = ({ offer }) => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { getStatusColor, showPrice } = useServiceOffers();
  const { tempUnfinishedFeature } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  return (
    <Card title={t("myServiceOffer")} sideText={t("submitted", { date: formatDate(offer.createdAt) })}>
      <div className="flex flex-col h-full space-y-4">
        <div className="flex-1 flex flex-col space-y-4">
          <AvatarInfoBox avatar={selectedCompany.logo} name={selectedCompany.name} />
          <p>{offer.message}</p>
          <p>{showPrice(offer)}</p>
          <div className="flex items-center justify-between">
            <DateInfoBox start={offer.date.start} end={offer.date.end} />
            <TenderInfoBox type="status" info={t("backend.enums.serviceOfferStatuses." + offer.status)} color={getStatusColor(offer.status)} modifiers="no-type" />
          </div>
        </div>
        <div className="flex items-center justify-end space-x-2">
          <Button
            action={() => {
              // TODO Add deleting of service offer possible
              tempUnfinishedFeature();
            }}
            label={t("delete")}
            modifiers="width-2xs no-margin"
          />
        </div>
      </div>
    </Card>
  );
};

export default MyServiceOffer;
