const usePlaces = () => {
  const isAddressObjectEmpty = (address) => {
    const emptyAddr = {
      address: "",
      apt: "",
      city: "",
      province: "",
      country: "",
      postalCode: ""
    };

    const isEmpty =
      address.address === emptyAddr.address &&
      address.apt === emptyAddr.apt &&
      address.city === emptyAddr.city &&
      address.province === emptyAddr.province &&
      address.country === emptyAddr.country &&
      address.postalCode === emptyAddr.postalCode;

    return isEmpty;
  };

  const getAddressStringFromObject = (address, showApt = false, showPostal = false) => {
    if (address) {
      return `${address.address}${showApt ? ` #${address.apt}` : ""}, ${address.city}, ${address.province}, ${address.country}${showPostal ? `, ${address.postalCode}` : ""}`;
    }

    return "";
  };

  const getCoordsFromGeocodeResult = (result) => {
    const location = result[0]?.geometry?.location;

    if (location) {
      return { lat: location.lat(), lng: location.lng() };
    }

    return null;
  };

  const getAddressObjectFromGeocodeResult = (result) => {
    let addr = {
      address: "",
      apt: "",
      city: "",
      province: "",
      country: "",
      postalCode: ""
    };

    let street_number = "",
      street = "";

    result[0].address_components.forEach((component) => {
      const types = component.types,
        shortName = component.short_name,
        longName = component.long_name;

      if (types.includes("street_number")) {
        street_number = longName;
      } else if (types.includes("route")) {
        street = longName;
      } else if (types.includes("locality")) {
        addr.city = longName;
      } else if (types.includes("administrative_area_level_1")) {
        addr.province = shortName;
      } else if (types.includes("country")) {
        addr.country = longName;
      } else if (types.includes("postal_code")) {
        addr.postalCode = longName;
      }
    });
    addr.address = `${street_number} ${street}`;

    return addr;
  };

  return {
    isAddressObjectEmpty,
    getAddressStringFromObject,
    getCoordsFromGeocodeResult,
    getAddressObjectFromGeocodeResult
  };
};

export default usePlaces;
