import React from "react";

const HeaderButton = ({ link, action, text }) => {
  return (
    <div className="relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:dark:bg-white after:bg-black after:w-0 after:h-0.5 hover:after:animate-slide-in">
      {link && <a href={`${link}`}>{text}</a>}
      {action && (
        <p onClick={action} className="hover:cursor-pointer">
          {text}
        </p>
      )}
    </div>
  );
};

export default HeaderButton;
