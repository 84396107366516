import React from "react";

const PopupButton = ({ textColor = "text-black dark:text-white", hoverBackground = "hover:bg-neutral-200 hover:dark:bg-neutral-600", action, link, className, children }) => {
  const CLASS_LIST = `relative block text-start w-full p-2 rounded-lg z-30 ${textColor} ${action || link ? hoverBackground : ""} ${className}`;

  if (link) {
    return (
      <a href={link} className={CLASS_LIST}>
        {children}
      </a>
    );
  } else if (action) {
    return (
      <button
        onClick={(event) => {
          event.stopPropagation();

          action();
        }}
        className={CLASS_LIST}
      >
        {children}
      </button>
    );
  }

  return <div className={CLASS_LIST}>{children}</div>;
};

export default PopupButton;
