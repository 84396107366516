import axios from "axios";
import i18n from "../../common/language/i18n";

import useDarkTheme from "../../hooks/useDarkTheme";
import useLogger from "../../hooks/useLogger";

const useJSOnChange = () => {
  const { showResponse } = useLogger();

  // eslint-disable-next-line no-unused-vars
  const [theme, setTheme] = useDarkTheme();

  const companiesPreferredDropdown = async (index) => {
    await axios
      .patch(
        process.env.REACT_APP_BACKEND_URL + "users/edit",
        {
          preferredCompany: index
        },
        { withCredentials: true }
      )
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const themeSwitcherDropdown = (index, prevData, items) => {
    const selectedTheme = items[index].name;

    setTheme(selectedTheme);
  };

  const languageSwitcherDropdown = (index, prevData, items) => {
    const preferredLanguage = items[index].name;

    i18n.changeLanguage(preferredLanguage);
  };

  const mfaPreferredDropdown = async (index, prevData, items) => {
    const preferredLoginMethod = index !== undefined ? items[index].name : "nothing";

    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + "users/edit", { preferredLoginMethod: preferredLoginMethod }, { withCredentials: true })
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  return { companiesPreferredDropdown, themeSwitcherDropdown, languageSwitcherDropdown, mfaPreferredDropdown };
};

export default useJSOnChange;
