import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronDown } from "react-icons/fi";
import { motion } from "framer-motion";

const Faq = () => {
  const { t } = useTranslation();

  return (
    <div className="px-4 py-12 text-gray-800 dark:text-white">
      <div className="max-w-3xl mx-auto text-justify">
        <h3 className="text-center text-3xl font-semibold mb-4">
         {t("faqTitle")}
        </h3>
        <Question title={t("faqQ1")} defaultOpen>
          <p>{t("faqA1")}</p>
        </Question>
        <Question title={t("faqQ1")}>
          <p>{t("faqA2")}</p>
        </Question>
        <Question title={t("faqQ3")}>
          <p>{t("faqA3")}</p>
        </Question>
        <Question title={t("faqQ4")}>
          <p>{t("faqA4")}</p>
        </Question>
      </div>
    </div>
  );
};

const Question = ({ title, children, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      className="border-b-[1px] border-b-slate-300 "
    >
      <button
        onClick={() => setOpen((pv) => !pv)}
        className="py-6 w-full flex items-center justify-between gap-4"
      >
        <motion.span
          variants={{
            open: {
              color: "rgb(234,74,73)"
            },
            closed: {
              color: "rgb(234,74,73)"
            }
          }}
          className="text-lg font-medium text-left bg-gradient-to-r from-hoptop-500 to-hoptop-500 bg-clip-text"
        >
          {title}
        </motion.span>
        <motion.span
          variants={{
            open: {
              rotate: "180deg",
              color: "rgb(234,74,73)"
            },
            closed: {
              rotate: "0deg",
              color: "#EA4A49"
            }
          }}
        >
          <FiChevronDown className="text-2xl" />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? "fit-content" : "0px",
          marginBottom: open ? "24px" : "0px"
        }}
        className="overflow-hidden text-[#EA4A49}"
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Faq;
