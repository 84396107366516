import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";

import domains from "../../../data/json/domainQuestions.json";

import Card from "../../../components/card/Card";
import MultiStepForm from "../../../components/forms/multistep/MultiStepForm";

const DashboardAddCompany = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();

  const ADD_COMPANY_NB_PAGES = 3;

  const [formData, setFormData] = useState({
    neq: "",
    companyName: "",
    email: "",
    phone: "",
    addr: {
      address: "",
      apt: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      isHome: false
    },
    operationRange: "",
    subdomains: [],
    domain: ""
  });

  const [selectedDomainIndex, setSelectedDomainIndex] = useState(null);

  const onChangeDomain = (index) => {
    if (index !== undefined) {
      setSelectedDomainIndex(index);
      setFormData({ ...formData, domain: domains[index].name });
    } else {
      setSelectedDomainIndex(null);
      setFormData({ ...formData, domain: "" });
    }
  };

  const addCompanyContent = [
    {
      title: t("addCompanyPage1"),
      content: [
        { type: "text", id: "neq", label: t("neq") },
        { type: "text", id: "companyName", label: t("companyName") },
        { type: "email", id: "email", label: t("email") },
        { type: "phone", id: "phone", label: t("phone") },
        { type: "num", id: "operationRange", label: t("operationRange") }
      ]
    },
    {
      title: t("addCompanyPage2"),
      content: [{ type: "address", mapType: "marker", hasHome: true }]
    },
    {
      title: t("addCompanyPage3"),
      content: [
        {
          type: "dropdown",
          id: "domain",
          name: "domain",
          label: t("selectDomain"),
          onChange: onChangeDomain,
          required: true,
          items: domains
        },
        selectedDomainIndex !== null && {
          type: "inputgroup",
          name: "subdomains",
          label: t("subdomains"),
          direction: "horizontal",
          inputs: domains[selectedDomainIndex].subdomains.map((subdomain) => {
            return { type: "checkbox", from: "subdomains", id: subdomain.name, name: subdomain.name, label: subdomain.label };
          })
        }
      ]
    }
  ];

  const addCompany = async () => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    delete newFormData.addr.isHome;
    if (newFormData.addr.apt === "") {
      delete newFormData.addr.apt;
    }

    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "companies/create-company",
        {
          neq: newFormData.neq,
          companyName: newFormData.companyName,
          email: newFormData.email,
          phone: newFormData.phone,
          addr: newFormData.addr,
          operationRange: newFormData.operationRange,
          subdomains: newFormData.subdomains
        },
        { withCredentials: true }
      )
      .then((response) => {
        showResponse(response);

        setTimeout(() => {
          window.location.assign("/dashboard");
        }, 2000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  return (
    <div>
      <Card title={t("dashboardAddCompany")}>
        <MultiStepForm nbPages={ADD_COMPANY_NB_PAGES} content={addCompanyContent} formData={formData} setFormData={setFormData} action={addCompany} />
      </Card>
    </div>
  );
};

export default DashboardAddCompany;
