import React from "react";
import { Link } from "react-scroll";

const HeaderButtonNav = ({ to, text, smooth = true, duration = 500 }) => {
  return (
    <div className={"cursor-pointer relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:dark:bg-white after:bg-black after:w-0 after:h-0.5 hover:after:animate-slide-in"}>
      <Link to={to} smooth={smooth} duration={duration} className="cursor-pointer">
        {text}
      </Link>
    </div>
  );
};

export default HeaderButtonNav;
