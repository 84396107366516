import React from "react";

import useGeneral from "../../../hooks/useGeneral";

const DateInfoBox = ({ start, end }) => {
  const { formatDate } = useGeneral();

  return (
    <div>
      <p className=""> {/*J'ai retiré le padding (p-1) pour ajuster le smallserviceoffercard*/}
        {formatDate(start)} — {formatDate(end)}
      </p>
    </div>
  );
};

export default DateInfoBox;
