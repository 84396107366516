import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import "tw-elements-react/dist/css/tw-elements-react.min.css";

import App from "./App";

// Fixes blank page issue on prod
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  })
}

const page = window.location.href;
if (process.env.REACT_APP_SERVER_ENVIRONMENT === "production" && page.startsWith(process.env.REACT_APP_WEBSITE_URL.replace("www.", ""))) {
  const newPage = page.split("/", 3).join("/").length;
  window.location.assign(`${process.env.REACT_APP_WEBSITE_URL}${page.substring(newPage + 1)}`);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
