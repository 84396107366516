import axios from "axios";

import useStripe from "../../hooks/useStripe";
import useLogger from "../../hooks/useLogger";
import { useTranslation } from "react-i18next";

const useStoredFunctions = () => {
  const { t } = useTranslation();

  const STRIPE = useStripe();
  const { showResponse, confirmAction } = useLogger();

  // Subscription
  const stripeStart = () => {
    STRIPE.openSubscriptionPage();
  };

  const stripeRenew = () => {
    STRIPE.renewSubscriptionPage();
  };

  const stripeCancel = () => {
    STRIPE.cancelMySubscription();
  };

  const stripeRestart = () => {
    STRIPE.restartMySubscription();
  };

  // MFA
  const setupApp = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `users/setup-otp`, null, { withCredentials: true })
      .then((response) => {
        // TODO: Show QR code from link provided in response, then ask for code and send it to PATCH users/confirm-otp
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const removeApp = async () => {
    await axios
      .delete(process.env.REACT_APP_BACKEND_URL + `users/delete-otp`, null, { withCredentials: true })
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const setupSMS = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `users/setup-phone`, null, { withCredentials: true })
      .then((response) => {
        // TODO: Ask for recovery phone, then ask for code and send it to PATCH users/confirm-phone
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const removeSMS = async () => {
    await axios
      .delete(process.env.REACT_APP_BACKEND_URL + `users/delete-phone`, null, { withCredentials: true })
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const setupEmail = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `users/setup-email`, null, { withCredentials: true })
      .then((response) => {
        // TODO: Ask for recovery email, then ask for code and send it to PATCH users/confirm-email
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const removeEmail = async () => {
    await axios
      .delete(process.env.REACT_APP_BACKEND_URL + `users/delete-email`, null, { withCredentials: true })
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const setupCodes = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `users/setup-codes`, null, { withCredentials: true })
      .then((response) => {
        // TODO: Show codes contained in response
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const removeCodes = async () => {
    await axios
      .delete(process.env.REACT_APP_BACKEND_URL + `users/delete-codes`, null, { withCredentials: true })
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  // Logins
  const logoutEverywhere = async () => {
    confirmAction(t("confirmLogoutEverywhere"), async () => {
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + `users/logout-everywhere`, null, { withCredentials: true })
        .then((response) => {
          showResponse(response);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const deleteAccount = async () => {
    confirmAction(t("confirmDeleteAccount"), async () => {
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + `users/delete`, { withCredentials: true })
        .then((response) => {
          showResponse(response);
          setTimeout(() => {
            window.location.assign("/");
          }, 2500);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  return {
    stripeStart,
    stripeRenew,
    stripeCancel,
    stripeRestart,
    setupApp,
    removeApp,
    setupSMS,
    removeSMS,
    setupEmail,
    removeEmail,
    setupCodes,
    removeCodes,
    logoutEverywhere,
    deleteAccount
  };
};

export default useStoredFunctions;
