import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../hooks/useLogger";

const useSaveButtonFunctions = ({ formData, setFormData, originalFormData, setOriginalFormData }) => {
  const { t } = useTranslation();

  const { showError, showResponse, confirmAction } = useLogger();

  const getNewValue = (name) => {
    const oldItem = originalFormData[name],
      newItem = formData[name];

    if (!Array.isArray(newItem)) {
      switch (typeof newItem) {
        case "string":
        default:
          if (newItem !== oldItem) {
            return newItem;
          }
          break;

        case "object":
          if (newItem?.apt === "") {
            const copy = { ...newItem };
            delete copy.apt;

            return copy;
          }
          return newItem;
      }
    } else {
      if (newItem.length !== oldItem.length) {
        return newItem;
      } else {
        let isDifferent = false;

        newItem.forEach((item, i) => {
          if (item !== oldItem[i]) {
            isDifferent = true;
          }
        });

        if (isDifferent) {
          return newItem;
        }
      }
    }

    return undefined;
  };

  const convertNotificationGroup = (array) => {
    const object = { email: false, sms: false };

    array.forEach((item) => {
      object[item] = true;
    });

    return object;
  };

  const account = async () => {
    confirmAction(t("confirmAccountEdit"), async () => {
      await axios
        .patch(
          process.env.REACT_APP_BACKEND_URL + "users/edit",
          {
            firstName: getNewValue("accountInfoFirstName"),
            lastName: getNewValue("accountInfoLastName"),
            email: getNewValue("accountInfoEmail"),
            phone: getNewValue("accountInfoPhone")
          },
          { withCredentials: true }
        )
        .then((response) => {
          showResponse(response);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const accountAddress = async () => {
    await axios
      .patch(
        process.env.REACT_APP_BACKEND_URL + "users/edit",
        {
          addr: getNewValue("accountAddressMapInput")
        },
        { withCredentials: true }
      )
      .then((response) => {
        showResponse(response);

        setTimeout(() => {
          window.location.assign("/dashboard/settings");
        }, 2000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const accountPassword = async () => {
    if (formData.accountPasswordNewInput === formData.accountPasswordNewConfirmInput) {
      await axios
        .patch(
          process.env.REACT_APP_BACKEND_URL + "users/edit",
          {
            currentPassword: formData.accountPasswordCurrentInput,
            newPassword: formData.accountPasswordNewInput
          },
          { withCredentials: true }
        )
        .then((response) => {
          showResponse(response);

          setTimeout(() => {
            window.location.assign("/dashboard/settings");
          }, 2000);
        })
        .catch((e) => {
          showResponse(e);
        });
    } else {
      showError("backend.error.passwordsDoNotMatch");
    }
  };

  const companies = async () => {
    const companyId = formData.companiesSelectedDropdown;

    confirmAction(t("confirmCompanyEdit"), async () => {
      await axios
        .patch(
          process.env.REACT_APP_BACKEND_URL + `companies/${companyId}/edit`,
          {
            companyName: getNewValue("companiesInfoName"),
            email: getNewValue("companiesInfoEmail"),
            phone: getNewValue("companiesInfoPhone"),
            operationRange: getNewValue("companiesInfoOperationRange"),
            subdomains: getNewValue("companiesSubdomainsCheckboxes")
          },
          { withCredentials: true }
        )
        .then((response) => {
          showResponse(response);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const companiesAddress = async () => {
    const companyId = formData.companiesSelectedDropdown;

    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + `companies/${companyId}/edit`, { addr: getNewValue("companiesAddressMapInput") }, { withCredentials: true })
      .then((response) => {
        showResponse(response);

        setTimeout(() => {
          window.location.assign("/dashboard/settings");
        }, 2000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const userNotifications = async () => {
    confirmAction(t("confirmUserNotificationsEdit"), async () => {
      await axios
        .patch(
          process.env.REACT_APP_BACKEND_URL + "users/edit-notifications",
          {
            login: convertNotificationGroup(formData.userNotificationsLoginGroup),
            newServiceOffer: convertNotificationGroup(formData.userNotificationsServiceOfferGroup),
            newMessage: convertNotificationGroup(formData.userNotificationsMessageGroup),
            newConversation: convertNotificationGroup(formData.userNotificationsConversationGroup)
          },
          { withCredentials: true }
        )
        .then((response) => {
          showResponse(response);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  // TODO
  const companyNotifications = async () => {
    const companyId = formData.companyNotificationsSelectedDropdown;

    confirmAction(t("confirmCompanyNotificationsEdit"), async () => {
      await axios
        .patch(
          process.env.REACT_APP_BACKEND_URL + `companies/${companyId}/edit-notifications`,
          {
            approvedServiceOffer: convertNotificationGroup(formData.companyNotificationsApprovedGroup),
            newTender: convertNotificationGroup(formData.companyNotificationsTenderGroup),
            newReview: convertNotificationGroup(formData.companyNotificationsReviewGroup),
            newConversation: convertNotificationGroup(formData.companyNotificationsConversationGroup),
            newMessage: convertNotificationGroup(formData.companyNotificationsMessageGroup)
          },
          { withCredentials: true }
        )
        .then((response) => {
          showResponse(response);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  return {
    account,
    accountAddress,
    accountPassword,
    companies,
    companiesAddress,
    userNotifications,
    companyNotifications
  };
};

export default useSaveButtonFunctions;
