import React, { useState } from "react";

import Card from "../../components/card/Card";
import FormMap from "../../components/map/FormMap";
import RegularMap from "../../components/map/RegularMap";

const Test = () => {
  const TEST_MAP_TYPE = "marker";

  const [formData, setFormData] = useState({
    addr: {
      address: "",
      apt: "",
      city: "",
      province: "",
      country: "",
      postalCode: ""
    }
  });

  return (
    <div className="min-h-screen mx-4 my-24 space-y-4">
      <h2 className="text-3xl font-semibold">Test Page</h2>
      <div className="flex space-x-6">
        <Card title="Form Map (with address)">
          <FormMap type={TEST_MAP_TYPE} formData={formData} setFormData={setFormData} hasHome />
        </Card>
        <Card title="Regular Map (with address)">
          <RegularMap type={TEST_MAP_TYPE} address={formData.addr} />
        </Card>
      </div>
      <div className="flex space-x-6">
        <Card title="Regular Map (with coords)">
          <RegularMap type={TEST_MAP_TYPE} coords={{ lat: undefined, lng: undefined }} />
        </Card>
      </div>
    </div>
  );
};

export default Test;
