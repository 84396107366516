import React from "react";

const HomeButton = ({ link, action, label, modifiers = "" }) => {
  return (
    <div
      className={`relative z-0 flex items-center justify-center bg-hoptop-500 md:text-lg text-white border border-transparent font-semibold w-full rounded-lg shadow-md cursor-pointer transition duration-150 ease-in-out hover:bg-hoptop-700 ${
        modifiers.includes("reverse") ? "!bg-transparent !border-hoptop-500 !text-hoptop-500 hover:!text-white hover:!bg-hoptop-500" : ""
      } ${
        modifiers.includes("reverse-animated")
          ? "overflow-hidden transition-all duration-500 hover:!bg-transparent before:absolute before:content-[''] before:bg-hoptop-500 before:translate-x-[150%] before:translate-y-[150%] before:scale-[2.5] before:inset-0 before:-z-10 before:rounded-[100%] before:transition-transform before:duration-1000 hover:text-white hover:before:translate-x-[0%] hover:before:translate-y-[0%] hover:scale-105 active:scale-95"
          : ""
      }`}
    >
      <a href={link} onMouseDown={action} className={`w-full h-full py-3 md:py-4 text-center rounded-lg ${modifiers.includes("height-sm") ? "!py-2 md:!py-2" : ""}`}>
        {label}
      </a>
    </div>
  );
};

export default HomeButton;
