import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Popup from "../../popup/Popup";

const MFAStatus = ({ status }) => {
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`relative ${status ? "bg-green-600 border-green-700" : "bg-red-600 border-red-700"} border-2 w-8 h-8 rounded-full shadow-md`}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Popup position="left" width="w-max" height="h-fit" shown={isHovered}>
        <p className="px-2">{t("mfaStatus", { status: status ? t("mfaEnabled") : t("mfaDisabled") })}</p>
      </Popup>
    </div>
  );
};

export default MFAStatus;
