/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useAuth from "../../../hooks/useAuth";
import useConversations from "../../../hooks/useConversations";
import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";
import { UserSocketContext } from "../../../contexts/UserSocketContext";

import Conversation from "../../../components/chat/Conversation";
import ConversationPreview from "../../../components/chat/elements/ConversationPreview";
import ConversationPreviewList from "../../../components/chat/ConversationPreviewList";

const DashboardMessages = () => {
  const { t } = useTranslation();

  const { isCompany } = useAuth();
  const { sortConversations } = useConversations();
  const { showResponse, showMessage } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);
  const userSocket = useContext(UserSocketContext);

  const [previews, setPreviews] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const getPreviewsAsUser = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "conversations/user", {
        withCredentials: true
      })
      .then((response) => {
        setPreviews(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const getPreviewsAsCompany = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `conversations/${!selectedCompany.isNull ? `company/${selectedCompany.id}` : "user"}`, {
        withCredentials: true
      })
      .then((response) => {
        setPreviews(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const getConversationMessages = async (id, index = 0) => {
    const { data } = await axios.get(process.env.REACT_APP_BACKEND_URL + `conversations/${id}/${index}`, { withCredentials: true }).catch((e) => {
      showResponse(e);
      return null;
    });

    if (selectedId) {
      const conversation = getConversationById(id);
      conversation.lastMessagesIndexLoaded = !data.isLastMessages ? index : -1;
    }

    return data.messages;
  };

  const populateConversations = async () => {
    if (previews.length > 0) {
      const conversations = await Promise.all(
        previews.map(async (preview) => {
          const previewCopy = { ...preview };
          delete previewCopy.lastMessage;

          return {
            ...previewCopy,
            messages: await getConversationMessages(previewCopy._id),
            lastMessagesIndexLoaded: 0
          };
        })
      );

      setConversations(conversations);
    }
  };

  const getConversationById = (id, source = conversations) => {
    return source.find((conversation) => conversation._id === id);
  };

  const onPreviewClick = (id) => {
    setSelectedId(id);
  };

  const addMessageToConversation = (conversationId, message) => {
    setConversations((prevState) => {
      const conversation = getConversationById(conversationId, prevState);
      conversation.messages.unshift(message);
      conversation.length > 25 && conversation.messages.pop();
      sortConversations(prevState);
      return [...prevState];
    });
  };

  const addOldMessagesToConversation = async (conversationId, messages) => {
    const conversation = getConversationById(conversationId);

    messages.forEach((message) => {
      conversation.messages.push(message);
    });
  };

  const fetchMoreMessages = async (conversationId, setIsLoading) => {
    const conversation = getConversationById(conversationId);

    if (conversation.lastMessagesIndexLoaded !== -1) {
      const newMessages = await getConversationMessages(conversationId, conversation.lastMessagesIndexLoaded + 1);
      addOldMessagesToConversation(conversationId, newMessages);

      setIsLoading(false);
    }
  };

  const changeRecipientStatus = (conversationId, status) => {
    setConversations((prevState) => {
      const conversation = getConversationById(conversationId, prevState);
      conversation.recipient.status = status;
      return [...prevState];
    });
  };

  const changeTypingStatus = (conversationId, typing) => {
    setConversations((prevState) => {
      const conversation = getConversationById(conversationId, prevState);
      conversation.recipient.typing = typing;
      return [...prevState];
    });
  };

  useEffect(() => {
    if (conversations.length > 0) {
      conversations.forEach((conversation) => {
        if (!userSocket.socket._callbacks[`$message_${conversation._id}`]) {
          // Messages
          userSocket.socket.on(`message_${conversation._id}`, (message) => {
            console.log(message);
            addMessageToConversation(conversation._id, { _id: message.messageId, text: message.text, author: message.author, createdAt: message.createdAt });
          });

          // Status
          userSocket.socket.on(`status_${conversation.recipient.id}`, (status) => {
            changeRecipientStatus(conversation._id, status);
          });

          // Typing...
          userSocket.socket.on(`typing_${conversation._id}_${conversation.recipient.id}`, (typing) => {
            changeTypingStatus(conversation._id, typing);
          });
        }
      });
    }
  }, [conversations]);

  useEffect(() => {
    populateConversations();
  }, [previews]);

  useEffect(() => {
    if (!isCompany()) {
      getPreviewsAsUser();
    } else if (isCompany() && selectedCompany.isNull !== null) {
      getPreviewsAsCompany();
    }
  }, [selectedCompany]);

  return (
    <div className="flex space-x-6 h-full">
      <ConversationPreviewList>
        <ConversationPreview
          type="add"
          onClick={() => {
            // TODO Show list of "contacts" (companies who have applied on currently active tenders, etc.)
            showMessage(t("comingSoon"), isCompany() ? t("howToCreateConversationsCompany") : t("howToCreateConversationsClient"));
          }}
        />
        <hr />
        {previews.length > 0 ? (
          previews.map((preview) => {
            return <ConversationPreview key={preview._id} preview={preview} selectedId={selectedId} onClick={onPreviewClick} />;
          })
        ) : (
          <p>{t("loading")}</p>
        )}
      </ConversationPreviewList>
      <Conversation conversation={getConversationById(selectedId)} fetchMoreMessages={fetchMoreMessages} />
    </div>
  );
};

export default DashboardMessages;
