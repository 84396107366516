import React from "react";

const Separator = ({ direction = "horizontal", className }) => {
  return (
    <div
      className={`border-neutral-200 dark:border-neutral-500 ${direction === "horizontal" ? "w-full h-0 border-t" : ""} ${
        direction === "vertical" ? "w-0 h-full border-l" : ""
      } ${className}`}
    ></div>
  );
};

export default Separator;
