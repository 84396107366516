/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import Card from "../../../components/card/Card";
import TendersList from "../../../components/tenders/TendersList";

const DashboardTenders = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [tenders, setTenders] = useState([]);

  const getTenders = async () => {
    await axios
      .patch(
        process.env.REACT_APP_BACKEND_URL + `tenders/browse/${selectedCompany.id}`,
        { subdomains: selectedCompany.subdomains },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        setTenders(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    if (selectedCompany.id && isFirstLoad) {
      getTenders();
      setIsFirstLoad(false);
    }
  }, [selectedCompany]);

  return (
    <div>
      <Card title={t("dashboardAllTenders")}>
        <TendersList tenders={tenders} />
      </Card>
    </div>
  );
};

export default DashboardTenders;
