import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { IconContext } from "react-icons";

import useGeneral from "../../../hooks/useGeneral";
// import useLogger from "../../../hooks/useLogger";
import useTenders from "../../../hooks/useTenders";

// import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
// import { FiMoreVertical } from "react-icons/fi";
// import { GiPin } from "react-icons/gi";

import AvatarInfoBox from "./AvatarInfoBox";
// import EyeToggle from "../../forms/inputs/EyeToggle";
// import Popup from "../../popup/Popup";
// import PopupButton from "../../popup/PopupButton";
import TenderInfoBox from "./TenderInfoBox";

const TenderCard = ({ id, user, title, description, city, createdAt, deliverBy, subdomain, urgent, status, loggedUserIsAuthor }) => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { getStatusColor, getPriorityColor } = useTenders();
  // const { tempUnfinishedFeature } = useLogger();

  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  // const [isPinned, setIsPinned] = useState(false);
  // const [isFavorited, setIsFavorited] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // const toggleDropdown = (event) => {
  //   event.stopPropagation();
  //   setDropdownOpen(!dropdownOpen);
  // };

  // const togglePin = () => {
  //   setIsPinned(!isPinned);

  //   // TODO Handle pin state update logic here
  // };

  // const toggleFavorite = () => {
  //   setIsFavorited(!isFavorited);

  //   // TODO Handle favorite state update logic here
  // };

  const handleOutsideClick = (event) => {
    if (dropdownOpen && !dropdownRef.current?.contains(event.target) && !dropdownButtonRef.current?.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <div
      onClick={() => {
        if (document.getSelection().type === "Range") {
          return null;
        }

        window.location.assign(`/dashboard/tenders/${id}`);
      }}
      className="flex flex-col justify-between gap-4 shadow-md rounded-xl font-rubik bg-neutral-50 dark:bg-neutral-700 cursor-pointer transition-transform hover:scale-[1.025] hover:shadow-lg"
    >
      <div className="flex items-center p-4 h-20 justify-between">
        <div className="flex-1 min-w-0 max-w-8xl mr-6">
          <div>
            <h3 className="w-fit text-xl font-semibold text-neutral-700 dark:text-neutral-100">{title}</h3>
          </div>
          <div className="h-5">
            <p className="w-fit max-w-full text-md text-neutral-550 dark:text-neutral-350 whitespace-nowrap overflow-hidden text-ellipsis">{description}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row flex-wrap items-center justify-center space-y-4 md:space-y-0 md:space-x-2 rounded-b-lg border-t border-b dark:border-neutral-500 border-neutral-250 text-center bg-neutral-100 dark:bg-neutral-600">
        <div className="flex flex-col text-center p-1 md:pr-1 md:pl-5 border-neutral-250 dark:border-neutral-500">
          <div>
            <p htmlFor={id} className="px-1 text-neutral-500 dark:text-neutral-300 text-xs">
              {t("status")}
            </p>
            <TenderInfoBox type="status" info={t("backend.enums.tenderStatuses." + status)} color={getStatusColor(status)} modifiers="no-type" />
          </div>
        </div>

        <div className="flex flex-col items-center text-center p-1 md:border-l md:pr-1 md:pl-5 border-neutral-250 dark:border-neutral-500">
          <div>
            <p htmlFor={id} className="px-1 text-neutral-500 dark:text-neutral-300 text-xs">
              {t("name")}
            </p>
            <AvatarInfoBox avatar={null} name={user} modifiers="avatar-xs text-xs text-not-bold" />
          </div>
        </div>

        <div className="flex flex-col text-center p-1 md:border-l md:pr-1 md:pl-5 items-center border-neutral-250 dark:border-neutral-500">
          <div className="text-center items-center">
            <p htmlFor={id} className="px-1 text-neutral-500 dark:text-neutral-300 text-xs text-center">
              {t("priority")}
            </p>
            <TenderInfoBox
              type="priority"
              info={t("backend.enums.priorities." + (urgent === true ? "urgent" : "normal"))}
              color={getPriorityColor(urgent === true ? "urgent" : "normal")}
              modifiers="no-type"
            />
          </div>
        </div>

        <div className="flex flex-col text-center p-1 md:border-l md:pr-1 md:pl-5 border-neutral-250 dark:border-neutral-500">
          <div>
            <p htmlFor={id} className="px-1 text-neutral-500 dark:text-neutral-300 text-xs text-center">
              {t("city")}
            </p>
            <div className="text-md text-neutral-700 dark:text-neutral-100">{city}</div>
          </div>
        </div>

        <div className="flex flex-col text-center p-1 md:border-l md:pr-1 md:pl-5 border-neutral-250 dark:border-neutral-500">
          <div>
            <p htmlFor={id} className="px-1 text-neutral-500 dark:text-neutral-300 text-xs text-center">
              {t("subdomain")}
            </p>
            <div className="text-md text-neutral-700 dark:text-neutral-100">{t(`tenderSubdomains.${subdomain}`)}</div>
          </div>
        </div>

        <div className="flex flex-col text-center p-1 md:border-l md:pr-1 md:pl-5 border-neutral-250 dark:border-neutral-500">
          <div>
            <p htmlFor={id} className="px-1 text-neutral-500 dark:text-neutral-300 text-xs">
              {t("created")}
            </p>
            <div className="text-md font-medium text-neutral-700 dark:text-neutral-100">{formatDate(createdAt)}</div>
          </div>
        </div>

        <div className="flex flex-col text-center p-1 md:border-l md:border-r md:pr-5 md:pl-6 border-neutral-250 dark:border-neutral-500">
          <div>
            <p htmlFor={id} className="px-1 text-neutral-500 dark:text-neutral-300 text-xs">
              {t("deliverBy")}
            </p>
            <div className="text-md font-medium text-neutral-700 dark:text-neutral-100">{formatDate(deliverBy)}</div>
          </div>
        </div>

        <div className="flex-1"></div>

        {/* 
        <div className="flex p-1 md:border-l border-neutral-250 dark:border-neutral-500">
          <EyeToggle />
        </div>
        */}
      </div>
    </div>
  );
};

export default TenderCard;
