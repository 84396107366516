import React from "react";
import { useTranslation } from "react-i18next";

const InputGroup = ({ className, direction = "vertical", label, items, children, modifiers = "" }) => {
  const { t } = useTranslation();

  return (
    <div className={`${className}`}>
      {!modifiers.includes("settings") && <p className="text-lg font-medium mb-2">{label}</p>}
      <div
        className={`flex ml-4 ${direction === "horizontal" ? "flex-row space-x-3" : direction === "vertical" ? "flex-col space-y-3" : ""} ${
          modifiers.includes("notifications") && "!space-x-8"
        }`}
      >
        {modifiers.includes("notifications")
          ? children.map((child, i) => {
              return (
                <div key={i} className={`flex flex-col items-center ${modifiers.includes("notifications-top") && "space-y-4"}`}>
                  {modifiers.includes("notifications-top") && <p>{t(items[i].id)}</p>}
                  {child}
                </div>
              );
            })
          : children}
      </div>
    </div>
  );
};

export default InputGroup;
