import React from "react";
import Tommy from "../../images/temp/tommy.JPG";
import Cassie from "../../images/temp/cassie.JPG";
import Sam from "../../images/temp/sam.JPG";
import Antho from "../../images/temp/antho.JPG";
import Lorenzo from "../../images/temp/lorenzo.JPG";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();

  const team = [
    {
      avatar: Tommy,
      name: "Tommy Paulhus", // Utilisez une clé de traduction appropriée
      title: t("team1Title"),
      desc: t("team1Text"),
      linkedin: "https://www.linkedin.com/in/tommypaulhus/"
    },
    {
      avatar: Cassie,
      name: "Cassie Bérubé",
      title: t("team2Title"),
      desc: t("team2Text"),
      linkedin: "https://www.linkedin.com/"
    },
    {
      avatar: Sam,
      name: "Samuel Granger",
      title: t("team3Title"),
      desc: t("team3Text"),
      linkedin: "https://www.linkedin.com/in/samuel-granger-823836278/"
    },
    {
      avatar: Antho,
      name: "Anthony Kessaris",
      title: t("team4Title"),
      desc: t("team4Text"),
      linkedin: "https://www.linkedin.com/in/anthony-kessaris/"
    },
    {
      avatar: Lorenzo,
      name: "Lorenzo Mignacca",
      title: t("team5Title"),
      desc: t("team5Text"),
      linkedin: "https://www.linkedin.com/in/lorenzo-mignacca-8a98682a9/"
    }
  ];

  return (
    <section className="py-14">
      <div className="max-w-screen-2xl mx-auto px-4 md:px-8">
        <div className="max-w-xl">
          <h3 className="text-white-800 text-3xl font-semibold sm:text-4xl">{t("teamTitle")}</h3>
          <p className="text-white-600 mt-3">{t("teamDescription")}</p>
        </div>

        <div className="mt-12">
          <ul className="grid gap-8 lg:grid-cols-2">
            {team.map((item, idx) => (
              <li key={idx} className="gap-8 sm:flex">
                <div className="w-full h-60">
                  <img src={item.avatar} className="w-full h-full object-cover object-center shadow-md rounded-xl" alt="" />
                </div>
                <div className="mt-4 sm:mt-0">
                  <h4 className="text-lg text-white-700 font-semibold">{item.name}</h4>
                  <p className="text-black dark:text-hoptop-50">{item.title}</p>
                  <p className="text-black dark:text-white mt-2">{item.desc}</p>
                  <div className="mt-3 flex gap-4 text-gray-400">
                    <a href={item.linkedin} target="_blank" rel="noreferrer">
                      <svg className="w-8 h-8 duration-150 hover:text-gray-500" fill="none" viewBox="0 0 48 48">
                        <g clipPath="url(#clip0_17_68)">
                          <path
                            fill="currentColor"
                            d="M44.447 0H3.544C1.584 0 0 1.547 0 3.46V44.53C0 46.444 1.584 48 3.544 48h40.903C46.407 48 48 46.444 48 44.54V3.46C48 1.546 46.406 0 44.447 0zM14.24 40.903H7.116V17.991h7.125v22.912zM10.678 14.87a4.127 4.127 0 01-4.134-4.125 4.127 4.127 0 014.134-4.125 4.125 4.125 0 010 8.25zm30.225 26.034h-7.115V29.766c0-2.653-.047-6.075-3.704-6.075-3.703 0-4.265 2.896-4.265 5.887v11.325h-7.107V17.991h6.826v3.13h.093c.947-1.8 3.272-3.702 6.731-3.702 7.21 0 8.541 4.744 8.541 10.912v12.572z"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_17_68">
                            <path fill="currentColor" d="M0 0h48v48H0z" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
export default Team;
