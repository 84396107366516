import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import useAuth from "../../../hooks/useAuth";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import { MdAddCircleOutline } from "react-icons/md";

import ProfilePopupButton from "./ProfilePopupButton";
import Separator from "../../Separator";
import Popup from "../../popup/Popup";

const ProfilePopup = ({ sidebarExpanded, popupOpen, togglePopup, buttonRef }) => {
  const { t } = useTranslation();

  const { logOut, companies, isCompany, profilePic, hasUnconfirmedSub, hasConfirmedSub } = useAuth();
  const selectedCompany = useContext(SelectedCompanyContext);

  // const popupClassName = `absolute bg-neutral-600 dark:bg-neutral-700 shadow-2xl text-sm w-56 min-h-1/4 p-1.5 space-y-1.5 rounded-lg z-30 ${
  //   popupOpen && sidebarExpanded ? "animate-grow-profile-popup-sidebar-expanded" : popupOpen && !sidebarExpanded ? "animate-grow-profile-popup-sidebar-closed" : "hidden"
  // }
  //   after:content-[''] after:absolute after:bg-neutral-600 after:dark:bg-neutral-700 after:shadow-2xl after:bottom-0 ${
  //     sidebarExpanded ? "after:left-1/2 after:-translate-x-3/4 after:translate-y-[calc(50%-0.1rem)]" : "after:left-0 after:-translate-x-[calc(50%-0.1rem)] after:-translate-y-3/4"
  //   } after:w-4 after:h-4 after:rotate-45 after:z-20`;

  const popupRef = useRef(null);

  const companyClick = (id) => {
    selectedCompany.setCompany(id);
  };

  const switchToUser = () => {
    selectedCompany.setCompany(null);
  };

  const handleOutsideClick = (event) => {
    if (popupOpen && !popupRef.current?.contains(event.target) && !buttonRef.current?.contains(event.target)) {
      togglePopup();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <Popup
      position={sidebarExpanded ? "top" : "right"}
      width="w-56"
      height="min-h-1/4"
      background="bg-neutral-600 dark:bg-neutral-700"
      modifiers={`${sidebarExpanded ? "start" : "end"}`}
      className="!p-1.5 !text-sm"
      shown={popupOpen}
    >
      <div ref={popupRef} className="space-y-1.5">
        <ProfilePopupButton
          type="icon"
          text={t("dashboardAddCompany")}
          image={<MdAddCircleOutline />}
          link={hasConfirmedSub() ? "/dashboard/add-company" : hasUnconfirmedSub() ? "/dashboard/settings/subscription" : "/pricing"}
        />
        {companies()?.map((company) => {
          return (
            <ProfilePopupButton
              key={company._id}
              type="avatar"
              companyId={company._id}
              text={company.displayName}
              // FIXME Profile pictures are not MVP & this one isn't supposed to be "profilePic", since it's a company, not the user
              image={profilePic()}
              action={companyClick}
            />
          );
        })}
        <Separator className="dark:border-neutral-200" />
        {isCompany() && (
          <>
            <ProfilePopupButton text={t("dashboardSwitchToUser")} action={switchToUser} />
            <Separator className="dark:border-neutral-200" />
          </>
        )}
        <ProfilePopupButton text={t("dashboardSettings")} link="/dashboard/settings" />
        <ProfilePopupButton text={t("dashboardLogOut")} action={logOut} textColor="text-red-500 dark:text-red-600" />
      </div>
    </Popup>
  );
};

export default ProfilePopup;
