import { useEffect, useState } from "react";

const useDarkTheme = () => {
  const [theme, setTheme] = useState(localStorage.theme);
  const colorTheme = theme === "dark" ? "light" : "dark";

  useEffect(() => {
    const root = window.document.documentElement;

    // TODO: Remove theme switcher from sidebar and website header and add a drop down menu with the 3 options in the settings

    if (theme === "sync") {
      if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        root.classList.remove("light");
        root.classList.add("dark");
      } else {
        root.classList.remove("dark");
        root.classList.add("light");
      }
    } else {
      root.classList.remove(colorTheme);
      root.classList.add(theme);
    }

    // Sets default theme to browser theme (which is usually synched with OS theme)
    if (!localStorage.getItem("theme")) {
      setTheme("sync");
    }

    localStorage.setItem("theme", theme);
  }, [theme, colorTheme]);

  return [colorTheme, setTheme];
};

export default useDarkTheme;
