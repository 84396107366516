/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

import useLogger from "../../hooks/useLogger";

const UnlockAccount = () => {
  const { showResponse } = useLogger();
  const { t } = useTranslation();

  const { userId, unlockCode } = useParams();
  const [message, setMessage] = useState(t("accountBeingUnlocked"));

  useEffect(() => {
    const unlockAccount = async () => {
      await axios
        .patch(process.env.REACT_APP_BACKEND_URL + `users/unlock-account/${userId()}/${unlockCode}`, null, { withCredentials: true })
        .then((response) => {
          setMessage(t("accountUnlocked"));
          showResponse(response);
          setTimeout(() => {
            window.location.assign("/login");
          }, 5000);
        })
        .catch((e) => {
          showResponse(e);
        });
    };

    unlockAccount();
  }, [unlockCode, t]);

  return (
    <div className="p-4">
      <p>{message}</p>
    </div>
  );
};

export default UnlockAccount;
