/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";

import useAuth from "../../hooks/useAuth";
import { UserSocketContext } from "../../contexts/UserSocketContext";
import { SelectedCompanyContext } from "../../contexts/SelectedCompanyContext";

import DashboardSidebar from "../../components/sidebar/DashboardSidebar";
import { SettingsContext } from "../../contexts/SettingsContext";

const Dashboard = ({ children }) => {
  const { isLoggedIn, companies, userId, switchToUser, switchToCompany } = useAuth();

  const SUBPAGE_URLS = [
    // "^/dashboard/settings/subscription/new/trial/annual/?$",
    // "^/dashboard/settings/subscription/new/trial/monthly/?$",
    "^/dashboard/settings/subscription/new/hop/annual/?$",
    "^/dashboard/settings/subscription/new/hop/monthly/?$",
    "^/dashboard/settings/subscription/new/top/annual/?$",
    "^/dashboard/settings/subscription/new/top/monthly/?$"
  ];

  const [socket, setSocket] = useState(null);

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [hasSelectedCompany, setHasSelectedCompany] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);
  const [selectedCompanySubdomains, setSelectedCompanySubdomains] = useState([]);
  const [selectedSettingsCategoryId, setSelectedSettingsCategoryId] = useState();
  const [selectedSettingsTabId, setSelectedSettingsTabId] = useState();
  const [selectedSettingsSubpageId, setSelectedSettingsSubpageId] = useState();

  if (!isLoggedIn()) {
    window.location.assign("/login");
  }

  const setCompany = (id) => {
    if (id !== null) {
      let selectedCompanyIndex;
      companies().forEach((company, i) => {
        if (company._id === id) {
          selectedCompanyIndex = i;
        }
      });

      setHasSelectedCompany(true);
      setSelectedCompanyId(id);
      switchToCompany(selectedCompanyIndex);
    } else {
      setHasSelectedCompany(false);
      setSelectedCompanyId(null);
      switchToUser();
    }

    window.location.assign("/dashboard");
  };

  const initSocket = async () => {
    setSocket(
      io(process.env.REACT_APP_SOCKET_URL, {
        withCredentials: true,
        query: {
          userId: userId(),
          companies: JSON.stringify((await companies()) || [])
        }
      })
    );
  };

  const settingsToLocalStorage = (categoryId, tabId, subpageId) => {
    if (categoryId && tabId) {
      localStorage.setItem("settingsCat", categoryId);
      localStorage.setItem("settingsTab", tabId);
    } else {
      localStorage.removeItem("settingsCat");
      localStorage.removeItem("settingsTab");

      localStorage.removeItem("settingsSelectedCompany");
      localStorage.removeItem("settingsSelectedCompanyNotifs");

      localStorage.removeItem("subscriptionTier");
      localStorage.removeItem("subscriptionCycle");
    }

    if (subpageId) {
      localStorage.setItem("settingsSub", subpageId);
    } else {
      localStorage.removeItem("settingsSub");
    }
  };

  const onSettingsTabClick = (categoryId, tabId, subpageId) => {
    setSelectedSettingsCategoryId(categoryId);
    setSelectedSettingsTabId(tabId);
    if (subpageId) {
      setSelectedSettingsSubpageId(subpageId);
    }

    settingsToLocalStorage(categoryId, tabId, subpageId);
  };

  const noSettingsSubpage = () => {
    SUBPAGE_URLS.forEach((item) => {
      if (window.location.pathname === item) {
        return false;
      }
    });

    return true;
  };

  useEffect(() => {
    const localStorageCompany = localStorage.getItem("company");

    if (localStorageCompany) {
      const selectedCompany = companies()[localStorageCompany];
      const defaultCompany = companies()[0];

      setHasSelectedCompany(true);
      setSelectedCompanyId(selectedCompany ? selectedCompany._id : defaultCompany._id);
      setSelectedCompanyName(selectedCompany ? selectedCompany.displayName : defaultCompany.displayName);
      setSelectedCompanySubdomains(selectedCompany ? selectedCompany.subdomains : defaultCompany.subdomains);
    } else {
      setHasSelectedCompany(false);
    }
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes("settings")) {
      settingsToLocalStorage(null, null, null);
    }

    if (noSettingsSubpage()) {
      const storageSettingCategory = localStorage.getItem("settingsCat"),
        storageSettingTab = localStorage.getItem("settingsTab");

      settingsToLocalStorage(storageSettingCategory, storageSettingTab, null);
    }
  }, []);

  useEffect(() => {
    initSocket();
  }, []);

  return (
    <UserSocketContext.Provider value={{ socket: socket }}>
      <SelectedCompanyContext.Provider
        value={{
          isNull: hasSelectedCompany !== null ? !hasSelectedCompany : null,
          id: selectedCompanyId,
          name: selectedCompanyName,
          logo: null,
          subdomains: selectedCompanySubdomains,
          setCompany: setCompany
        }}
      >
        <SettingsContext.Provider
          value={{
            selectedCategoryId: selectedSettingsCategoryId,
            selectedTabId: selectedSettingsTabId,
            selectedSubpageId: selectedSettingsSubpageId,
            onTabClick: onSettingsTabClick,
            toLocalStorage: settingsToLocalStorage
          }}
        >
          <div className="min-h-screen flex bg-neutral-100 dark:bg-neutral-800">
            <DashboardSidebar sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
            <div
              className={`fixed bg-black opacity-50 hidden top-0 bottom-0 left-0 right-0 z-10 cursor-pointer transition-all ${sidebarExpanded && "!block"}`}
              onClick={() => {
                setSidebarExpanded(false);
              }}
            />
            <div className={`relative flex flex-col w-[calc(100svw-(100svw-100%)-5rem)] ml-20 p-6 space-y-6 ${sidebarExpanded && "blur-xs"}`}>{children}</div>
          </div>
        </SettingsContext.Provider>
      </SelectedCompanyContext.Provider>
    </UserSocketContext.Provider>
  );
};

export default Dashboard;
