import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useGeneral from "../../../hooks/useGeneral";
import useLogger from "../../../hooks/useLogger";
import usePlaces from "../../../hooks/usePlaces";
import useTenders from "../../../hooks/useTenders";

import domainQuestions from "../../../data/json/domainQuestions.json";

import Card from "../../../components/card/Card";
import MultiStepForm from "../../../components/forms/multistep/MultiStepForm";
import CreateTenderReviewCard from "../../../components/card/createTenderReview/CreateTenderReviewCard";
import CreateTenderReviewItem from "../../../components/card/createTenderReview/CreateTenderReviewItem";

const DashboardCreateTenders = () => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { showResponse } = useLogger();
  const { getAddressStringFromObject } = usePlaces();
  const { showQuestion, showQuestionAnswer } = useTenders();

  const NB_PAGES = 5;

  const [tenderData, setTenderData] = useState({
    title: "",
    description: "",
    addr: {
      address: "",
      apt: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      isHome: false
    },
    deliverBy: "",
    urgent: false,
    domain: "",
    subdomain: "",
    service: "",
    questions: []
  });

  const [selectedDomainIndex, setSelectedDomainIndex] = useState(null);
  const [selectedSubdomainIndex, setSelectedSubdomainIndex] = useState(null);

  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedSubdomain, setSelectedSubdomain] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [questions, setQuestions] = useState([]);

  const onChangeDomain = (index) => {
    let newPrevData;

    if (index !== undefined) {
      const domain = domainQuestions[index];

      setSelectedDomainIndex(index);
      setSelectedDomain(domain);

      newPrevData = { ...tenderData, domain: domain.name };
    }

    if (index === undefined) {
      setSelectedDomainIndex(null);
      setSelectedDomain(null);

      newPrevData = { ...tenderData, domain: "" };
    }

    onChangeSubdomain(undefined, newPrevData);
  };

  const onChangeSubdomain = (index, prevData) => {
    let newPrevData;

    if (index !== undefined) {
      const subdomain = domainQuestions[selectedDomainIndex].subdomains[index];

      setSelectedSubdomainIndex(index);
      setSelectedSubdomain(subdomain);

      if (!prevData) {
        newPrevData = { ...tenderData, subdomain: subdomain.name };
      } else {
        newPrevData = { ...prevData, subdomain: subdomain.name };
      }
    }

    if (index === undefined) {
      setSelectedSubdomainIndex(null);
      setSelectedSubdomain(null);

      if (!prevData) {
        newPrevData = { ...tenderData, subdomain: "" };
      } else {
        newPrevData = { ...prevData, subdomain: "" };
      }
    }

    onChangeService(undefined, newPrevData);
  };

  const onChangeService = (index, prevData) => {
    if (index !== undefined) {
      const service = domainQuestions[selectedDomainIndex].subdomains[selectedSubdomainIndex].services[index];

      setSelectedService(service);
      setQuestions(service.questions);

      if (!prevData) {
        setServiceAndQuestionsInTenderData(tenderData, service);
      } else {
        setServiceAndQuestionsInTenderData(prevData, service);
      }
    }

    if (index === undefined) {
      setSelectedService(null);
      setQuestions([]);

      if (!prevData) {
        setServiceAndQuestionsInTenderData(tenderData, null);
      } else {
        setServiceAndQuestionsInTenderData(prevData, null);
      }
    }
  };

  const setServiceAndQuestionsInTenderData = (data, service) => {
    if (service) {
      let questions = [];
      service.questions.forEach((question) => {
        questions.push({ q: "q" + (question.index + 1), a: "" });
      });

      setTenderData({
        ...data,
        service: service.name,
        questions: questions
      });
    } else {
      setTenderData({
        ...data,
        service: "",
        questions: []
      });
    }
  };

  const tenderContent = [
    {
      title: t("createTenderPage2"),
      content: [
        {
          type: "dropdown",
          id: "domain",
          name: "domain",
          label: t("selectDomain"),
          onChange: onChangeDomain,
          required: true,
          items: domainQuestions
        },
        selectedDomain
          ? {
              type: "dropdown",
              id: "subdomain",
              name: "subdomain",
              label: t("selectSubdomain"),
              onChange: onChangeSubdomain,
              required: true,
              items: selectedDomain?.subdomains
            }
          : null,
        selectedSubdomain
          ? {
              type: "dropdown",
              id: "service",
              name: "service",
              label: t("selectService"),
              onChange: onChangeService,
              required: true,
              items: selectedSubdomain?.services
            }
          : null
      ]
    },
    {
      title: t("createTenderPage4"),
      content: [
        { type: "text", id: "title", label: t("title"), required: true },
        { type: "textarea", id: "description", label: t("description"), required: true },
        { type: "date", id: "deliverBy", label: t("deliverBy"), required: true },
        { type: "checkbox", id: "urgent", name: "urgent", label: t("isUrgent"), required: false }
      ]
    },

    { title: t("createTenderPage3"), content: selectedService !== null ? questions : [] },
    {
      title: t("createTenderPage1"),
      content: [{ type: "address", mapType: "marker", hasHome: true }]
    },
    {
      title: t("createTenderPage5"),
      content: (
        <div className="space-y-4">
          <CreateTenderReviewCard direction="grid">
            <CreateTenderReviewItem label={t("title")} value={tenderData.title} />
            <CreateTenderReviewItem label={t("description")} value={tenderData.description} />
            <CreateTenderReviewItem label={t("address.address")} value={getAddressStringFromObject(tenderData.addr, tenderData.addr.apt !== "", true)} />
            
          </CreateTenderReviewCard>
          <div className="flex space-x-4">
            <CreateTenderReviewCard direction="horizontal">
              <CreateTenderReviewItem label={t("deliverBy")} value={formatDate(tenderData.deliverBy)} />
              <CreateTenderReviewItem label={t("urgent")} value={tenderData.urgent && t(tenderData.urgent ? "yes" : "no")} />
            </CreateTenderReviewCard>
            <CreateTenderReviewCard direction="horizontal">
              <CreateTenderReviewItem label={t("domain")} value={tenderData.domain && t("tenderDomains." + tenderData.domain)} />
              <CreateTenderReviewItem label={t("subdomain")} value={tenderData.subdomain && t("tenderSubdomains." + tenderData.subdomain)} />
              <CreateTenderReviewItem label={t("service")} value={tenderData.service && t("tenderServices." + tenderData.service)} />
            </CreateTenderReviewCard>
          </div>
          <CreateTenderReviewCard direction="grid">
            {tenderData.questions?.map((question, i) => {
              return <CreateTenderReviewItem key={i} label={showQuestion(tenderData, question.q)} value={showQuestionAnswer(question.a)} />;
            })}
          </CreateTenderReviewCard>
        </div>
      )
    }
  ];

  const createTender = async (event) => {
    if (event) {
      event.preventDefault();
    }

    const newTenderData = JSON.parse(JSON.stringify(tenderData));
    delete newTenderData.addr.isHome;
    if (newTenderData.addr.apt === "") {
      delete newTenderData.addr.apt;
    }

    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "tenders/create-tender", newTenderData, { withCredentials: true })
      .then((response) => {
        showResponse(response);

        setTimeout(() => {
          window.location.assign(`/dashboard/tenders/${response.data.id}`);
        }, 2000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  return (
    <div>
      <Card title="Create a new tender">
        <MultiStepForm nbPages={NB_PAGES} content={tenderContent} formData={tenderData} setFormData={setTenderData} action={createTender} buttonLabel={t("createTender")} />
      </Card>
    </div>
  );
};

export default DashboardCreateTenders;
