import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useImagePreloader from "../../hooks/useImagePreloader";

import headerLogo from "../../images/temp/header_logo_light.png";
import headerLogoDark from "../../images/temp/header_logo_dark.png";

import Form from "../../components/forms/Form";

const preloadSrcList = [headerLogo, headerLogoDark];

const UnderConstruction = ({ setPassword }) => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    password: ""
  });

  const loginContent = [{ type: "password", id: "password", label: t("constructionPassword"), required: true, modifiers: "horizontal-fill" }];

  return (
    <div className="flex items-center justify-center bg-neutral-50 dark:bg-neutral-900 w-screen h-screen">
      <div className="flex flex-col items-center w-1/2 min-h-1/2 p-10 space-y-10">
        <div className="flex flex-col items-center justify-center">
          <img
            src={localStorage.getItem("theme") === "dark" ? headerLogoDark : localStorage.getItem("theme") === "light" ? headerLogo : headerLogo}
            alt="Hoptop logo"
            className="w-3/4"
          />
          <p className="text-xl font-semibold mt-2">{t("underConstruction")}</p>
        </div>
        <div className="flex items-center w-3/5 space-x-6">
          {/* <div className="flex items-center w-9/12 space-x-6"> */}
          <div className="flex flex-col items-center justify-end w-full">
            <Form
              content={loginContent}
              formData={formData}
              setFormData={setFormData}
              action={(event) => {
                event.preventDefault();

                setPassword(formData.password);
                setFormData({ password: "" });
              }}
              className="w-full"
              modifiers="centered buttons-w-3/4 no-spacing"
            />
          </div>
          {/* <div className="flex flex-col items-center justify-end w-full space-y-2">
            <p className="pt-4">Do you want to stay up to date?</p>
            <Button
              action={() => {
                console.log("Test.");
              }}
              label="Subscribe to the newsletter"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
