/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { IconContext } from "react-icons";

import useAuth from "../../../hooks/useAuth";
import useLogger from "../../../hooks/useLogger";
import usePlaces from "../../../hooks/usePlaces";

import { MdHome } from "react-icons/md";

import Input from "../../forms/inputs/Input";
import Separator from "../../Separator";

const AutocompleteInput = ({ setCoords, address, setAddress, setIsHome, hasHome = false }) => {
  const { t } = useTranslation();

  const { isLoggedIn } = useAuth();
  const { showResponse } = useLogger();
  const { isAddressObjectEmpty, getAddressStringFromObject, getCoordsFromGeocodeResult, getAddressObjectFromGeocodeResult } = usePlaces();

  const places = useMapsLibrary("places");
  const geocoding = useMapsLibrary("geocoding");
  const [autocomplete, setAutocomplete] = useState(null);
  const [geocoder, setGeocoder] = useState(null);

  const [currentUser, setCurrentUser] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const [predictions, setPredictions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [geocodeResult, setGeocodeResult] = useState();

  const onChange = (e) => {
    setInputValue(e.target.value);
  };

  const selectAddress = (addressString, isHome) => {
    setInputValue(addressString);
    setIsHome(isHome);

    geocoder.geocode({ address: addressString }, (result) => {
      setGeocodeResult(result);
    });
  };

  const onSelect = (i) => {
    const selectedPlace = predictions[i];

    selectAddress(selectedPlace.description, false);
    setIsFirstLoad(false);
  };

  const selectHome = () => {
    selectAddress(getAddressStringFromObject(currentUser.addr), true);
    setIsFirstLoad(false);
  };

  const getCurrentUser = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "users/current-user", {
        withCredentials: true
      })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    if (isLoggedIn()) {
      getCurrentUser();
    }
  }, []);

  useEffect(() => {
    if (geocoder && !isAddressObjectEmpty(address) && isFirstLoad) {
      selectAddress(getAddressStringFromObject(address), false);
      setIsFirstLoad(false);
    }
  }, [address, geocoder]);

  useEffect(() => {
    if (places) {
      setAutocomplete(new places.AutocompleteService());
    }
  }, [places]);

  useEffect(() => {
    if (geocoding) {
      setGeocoder(new geocoding.Geocoder());
    }
  }, [geocoding]);

  useEffect(() => {
    if (autocomplete) {
      autocomplete.getPlacePredictions({ input: inputValue }, (predictions) => {
        setPredictions(predictions);
      });
    }
  }, [autocomplete, inputValue]);

  useEffect(() => {
    if (geocodeResult) {
      const newAddress = getAddressObjectFromGeocodeResult(geocodeResult);
      newAddress.apt = address.apt;

      setCoords(getCoordsFromGeocodeResult(geocodeResult));
      setAddress(newAddress);
    }
  }, [geocodeResult]);

  return (
    <div className="relative">
      <Input
        id="autocomplete"
        label={t("address.searchAddress")}
        value={inputValue}
        onChange={onChange}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        className={isFocused && "!rounded-b-none"}
      />
      <ul
        className={`absolute bg-neutral-50 dark:bg-neutral-750 w-full min-h-10 p-1 space-y-1 rounded-b-lg shadow-2xl z-10 ${
          isFocused ? "visible border border-t-0 border-neutral-500 dark:border-neutral-300" : "hidden"
        }`}
      >
        {hasHome && (
          <>
            <li className="flex items-center p-2 space-x-1 hover:bg-neutral-200 dark:hover:bg-neutral-800 hover:cursor-pointer rounded-md" onMouseDown={selectHome}>
              <IconContext.Provider value={{ className: "w-5 h-full" }}>
                <MdHome />
              </IconContext.Provider>
              <p className="flex-1">{t("yourHome")}</p>
            </li>
            <Separator />
          </>
        )}
        {predictions?.length !== 0 &&
          predictions?.map((prediction, i) => (
            <li
              className="p-2 hover:bg-neutral-200 dark:hover:bg-neutral-800 cursor-pointer rounded-md"
              key={prediction.place_id}
              onMouseDown={() => {
                onSelect(i);
              }}
            >
              {prediction.description}
            </li>
          ))}
        {predictions?.length === 0 && <li className="p-2">{t("typeAddress")}</li>}
      </ul>
    </div>
  );
};

export default AutocompleteInput;
