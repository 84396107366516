/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";

import DashboardSettings from "../dashboard/settings/DashboardSettings";

const Subscribe = ({ type = "start", tier, cycle }) => {
  const CATEGORY_ID = "general";
  const TAB_ID = "subscription";
  const SUBPAGE_ID = "subscriptionStart";

  useEffect(() => {
    localStorage.setItem("subscriptionTier", tier);
    localStorage.setItem("subscriptionCycle", cycle);
  });

  return <DashboardSettings categoryId={CATEGORY_ID} tabId={TAB_ID} subpageId={SUBPAGE_ID} prevPage={"/pricing"} />;
};

export default Subscribe;
