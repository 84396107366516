/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";

import Card from "../../../components/card/Card";
import TendersList from "../../../components/tenders/TendersList";

const DashboardMyTenders = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();

  const [myTenders, setMyTenders] = useState(null);

  const getUserTenders = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "users/current-user/tenders", {
        withCredentials: true
      })
      .then((response) => {
        setMyTenders(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    getUserTenders();
  }, []);

  return (
    <div>
      <Card title={t("dashboardMyTenders")}>
        <TendersList type="myTenders" tenders={myTenders} />
      </Card>
    </div>
  );
};

export default DashboardMyTenders;
