import { GoogleMapsContext, useMapsLibrary } from "@vis.gl/react-google-maps";
import React, { useContext, useRef } from "react";

const Circle = ({ position, radius, ...circleOptions }) => {
  const maps = useMapsLibrary("maps");
  const map = useContext(GoogleMapsContext)?.map;

  const circle = useRef(new maps.Circle()).current;
  circle.setOptions(circleOptions);
  circle.setCenter(position);
  circle.setRadius(radius);
  circle.setMap(map);

  return <></>;
};

export default Circle;
