import Swal from "sweetalert2/dist/sweetalert2";
import { useTranslation } from "react-i18next";

const useLogger = () => {
  const { t } = useTranslation();

  // From frontend validations
  const showError = (error) => {
    Swal.fire({
      title: t("error"),
      text: t(error),
      icon: "error",
      confirmButtonText: t("ok"),
      customClass: {
        confirmButton: "!bg-hoptop-500 !dark:bg-hoptop-600 !shadow-none"
      }
    });
  };

  // Coming from backend
  const showResponse = (response) => {
    const res = response ? betterResponse(response) : null;
    const type = response ? responseType(response) : "error";

    Swal.fire({
      title: t(type),
      text: res?.data ? t(`backend.${type}.${res.data.message}`) : t(`backend.${type}.unknown`),
      icon: type,
      confirmButtonText: t("ok"),
      customClass: {
        confirmButton: "!bg-hoptop-500 !dark:bg-hoptop-600 !shadow-none"
      }
    });

    if (res?.data && (res.data.message === "authInvalid" || res.data.message === "verification")) {
      setTimeout(() => {
        window.location.assign("/login");
      }, 2500);
    }
  };

  const showMessage = (title, text, icon = "info") => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: t("ok"),
      customClass: {
        htmlContainer: "!text-base whitespace-break-spaces break-words",
        confirmButton: "!bg-hoptop-500 !dark:bg-hoptop-600 !shadow-none"
      }
    });
  };

  const askForInput = (title, text, inputType, inputLabel) => {
    return Swal.fire({
      title: title,
      text: text,
      input: inputType,
      inputPlaceholder: inputLabel,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t("ok"),
      cancelButtonText: t("cancel"),
      customClass: {
        htmlContainer: "!text-base whitespace-break-spaces break-words",
        confirmButton: "!bg-hoptop-500 !dark:bg-hoptop-600 !shadow-none"
      }
    });
  };

  const logResponse = (response) => {
    try {
      let res = response ? JSON.parse(JSON.stringify(betterResponse(response))) : null;
      const type = response ? responseType(response) : "error";
      const message = response && res.data ? res.data.message : "unknown";

      response && res.data ? (res.data.message = t(`backend.${type}.${message}`)) : (res = t(`backend.${type}.${message}`));
      type === "success" ? console.log(res) : console.error(res);
    } catch (e) {
      console.error(e);
    }
  };

  const confirmAction = (message, action, refuseAction = null) => {
    Swal.fire({
      title: t("confirmAction"),
      text: message,
      icon: "question",
      showDenyButton: true,
      confirmButtonText: t("yes"),
      denyButtonText: t("no"),
      customClass: {
        confirmButton: "!bg-green-500 !dark:bg-green-600 !shadow-none",
        denyButton: "!bg-red-500 !dark:bg-red-600 !shadow-none"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        action();
      } else if (result.isDenied) {
        if (refuseAction !== null) {
          refuseAction();
        }
      }
    });
  };

  const tempUnfinishedFeature = () => {
    Swal.fire({
      title: t("hi"),
      text: t("unfinished"),
      icon: "info",
      confirmButtonText: t("ok"),
      customClass: {
        htmlContainer: "!text-base whitespace-break-spaces break-words",
        confirmButton: "!bg-hoptop-500 !dark:bg-hoptop-600 !shadow-none"
      }
    });
  };

  // Utils
  function responseType(response) {
    return response.status === 200 ? "success" : "error";
  }

  function betterResponse(response, type) {
    return response.status === 200 ? response : response.request?.status === 429 ? { data: { message: "tooManyRequests" } } : response.response;
  }

  return {
    showError,
    showResponse,
    showMessage,
    askForInput,
    logResponse,
    confirmAction,
    tempUnfinishedFeature
  };
};

export default useLogger;
