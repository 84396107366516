import React from "react";
import { useTranslation } from "react-i18next";

import { AnimatePresence, motion } from "framer-motion";

import Button from "../button/Button";

const SubscriptionTierInfo = ({ cycle, plan }) => {
  const { t } = useTranslation();

  return (
    <th className="text-start font-normal p-4 space-y-4">
      <p>{plan.name}</p>
      <div className="overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.p
            key={cycle}
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            transition={{ ease: "linear", duration: 0.25 }}
            className="space-x-1"
          >
            <span className="font-bold text-4xl">{plan.price}</span>
            <span>{cycle === "monthly" ? t("monthPricing") : cycle === "annual" && t("yearPricing")}</span>
          </motion.p>
        </AnimatePresence>
      </div>
      <p className="text-sm">{plan.description}</p>
      <Button link={plan.link} label={plan.button} />
    </th>
  );
};

export default SubscriptionTierInfo;
