import React from "react";

const CreateTenderReviewItem = ({ label, value }) => {
  return (
    <div className="flex-1 basis-2/5">
      <h5 className="text-lg font-medium mb-0.5">{label}</h5>
      <p>{value}</p>
    </div>
  );
};

export default CreateTenderReviewItem;
