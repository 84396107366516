import React from "react";
import { useTranslation } from "react-i18next";

import useImagePreloader from "../../hooks/useImagePreloader";

import Logo from "../../images/temp/logoaccueil.png";

const preloadSrcList = [Logo];

const NotFound = () => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center min-h-screen px-4 md:px-8">
      <div className="text-center w-full space-y-2">
        <img src={Logo} alt="logo" draggable="false" className="mx-auto" />
        <h3 className="text-hoptop-500 font-semibold text-4xl md:text-5xl">{t("notFoundTitre")}</h3>
        <p className="text-neutral-800 dark:text-neutral-50 font-semibold text-2xl md:text-3xl">{t("notFound")}</p>
        <p className="text-neutral-600 dark:text-neutral-300 md:text-lg">{t("notFoundDes")}</p>
      </div>
    </div>
  );
};

export default NotFound;
