import React, { useState } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import useDarkTheme from "../../hooks/useDarkTheme";

const ThemeSwitcher = ({ goToDarkTheme, toggleTheme, sunColor, moonColor }) => {
  const [theme, setTheme] = useDarkTheme();
  const [goToDarkThemeNotProp, setGoToDarkThemeNotProp] = useState(theme === "dark" ? true : false);

  const toggleThemeNotProp = () => {
    setTheme(theme);
    setGoToDarkThemeNotProp(theme === "light");
  };

  return (
    <DarkModeSwitch
      checked={goToDarkTheme !== undefined ? goToDarkTheme : goToDarkThemeNotProp}
      onChange={toggleTheme !== undefined ? toggleTheme : toggleThemeNotProp}
      size={56}
      className="w-full h-full"
      sunColor={sunColor !== undefined ? sunColor : "white"}
      moonColor={moonColor !== undefined ? moonColor : "black"}
    />
  );
};

export default ThemeSwitcher;
