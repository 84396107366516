import { useTranslation } from "react-i18next";

const useTenders = () => {
  const { t } = useTranslation();

  const showQuestion = (tender, question) => {
    return t(`tenderQuestions.${tender.domain}.${tender.subdomain}.${tender.service}.${question}`);
  };

  const showQuestionAnswer = (value) => {
    return typeof value === "boolean" ? (value === true ? t("yes") : t("no")) : value;
  };

  const getStatusColor = (status) => {
    const STATUS_COLORS = {
      open: "green",
      ongoing: "yellow",
      completed: "greenWild",
      cancelled: "red",
      closed: "default",
      abandoned: "default" 
    };

    return STATUS_COLORS[status];
  };

  const getPriorityColor = (priority) => {
    const PRIORITY_COLORS = {
      normal: "blue",
      urgent: "redFort"
    };

    return PRIORITY_COLORS[priority];
  };

  return { showQuestion, showQuestionAnswer, getStatusColor, getPriorityColor };
};

export default useTenders;
