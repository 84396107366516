import React from "react";
import { useTranslation } from "react-i18next";

import { IoIosStar } from "react-icons/io";

import useAuth from "../../hooks/useAuth";
import SidebarButton from "./SidebarButton";

const SubscribeBanner = ({ sidebarExpanded }) => {
  const { t } = useTranslation();

  const { hasNoSub, hasUnconfirmedSub } = useAuth();

  return (
    <div
      className={`relative bg-hoptop-500 w-[4.75rem] min-h-10 -ml-5 px-4 py-2 shadow-lg transition-all ${sidebarExpanded ? "!w-[18.75rem]" : ""}

        before:content-[''] before:absolute before:right-0 before:bottom-0
        before:w-10 before:h-full before:-translate-x-[0.85rem] before:translate-y-[2.1rem] before:rotate-45
        before:bg-hoptop-800 before:-z-10 before:shadow-lg

        after:content-[''] after:absolute after:right-0 after:bottom-0
        after:w-10 after:h-full after:translate-x-4 after:translate-y-2
        after:bg-hoptop-600 after:-z-20 after:shadow-lg after:ribbon-mask
      `}
    >
      <div className={`absolute top-full left-1 bg-neutral-800 dark:bg-neutral-950 w-16 h-full transition-all ${sidebarExpanded && "!w-72"}`} />
      <SidebarButton
        type="subscribe"
        link={hasNoSub() ? "/pricing" : hasUnconfirmedSub() && "/dashboard/settings/subscription"}
        icon={<IoIosStar />}
        label={t("subscribe")}
        sidebarExpanded={sidebarExpanded}
      />
    </div>
  );
};

export default SubscribeBanner;
