import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

import Flag from "react-world-flags";
import PopupButton from "../../../components/popup/PopupButton";

const LanguageButton = ({ code, toggleMenu }) => {
  const { t } = useTranslation();

  const parseCode = (code) => {
    const EXCEPTIONS = [{ lang: "en", flag: "gb" }];

    const newCode = EXCEPTIONS.find((exception) => {
      if (exception.lang === code) {
        return exception;
      }

      return null;
    });

    if (newCode) {
      return newCode.flag;
    }

    return code;
  };

  return (
    <PopupButton
      action={() => {
        i18n.changeLanguage(code);
        toggleMenu();
      }}
      className={`${i18n.language === code ? "!bg-neutral-200 dark:!bg-neutral-600" : ""}`}
    >
      <div className="flex items-center space-x-1.5">
        <Flag code={parseCode(code)} className="w-8 h-5 object-cover" />
        <p className="text-black dark:text-white">{t(code)}</p>
      </div>
    </PopupButton>
  );
};

export default LanguageButton;
