import React from "react";
import { useTranslation } from "react-i18next";

import Card from "../card/Card";

const ConversationPreviewList = ({ type = "dashboardMessages", children }) => {
  const { t } = useTranslation();

  switch (type) {
    default:
    case "dashboardMessages":
      return (
        <Card modifiers="small padding-xs" className="flex-1/5">
          <div className="flex flex-col space-y-2">{children}</div>
        </Card>
      );
    case "dashboardHome":
      return (
        <Card title={t("dashboardMessages")} modifiers="title-sm" className="shadow-xl shadow-red-600/40 dark:shadow-red-600/35">
          <div className="flex flex-col text-ellipsis overflow-hidden max-w-sm">{children}</div>
        </Card>
      );
  }
};

export default ConversationPreviewList;
