import React from "react";

const Popup = ({ position = "top", width = "w-48", height = "h-48", background = "bg-neutral-300 dark:bg-neutral-700", borders, modifiers = "", className, shown, children }) => {
  const COMMON_CLASSES = `absolute p-1 rounded-lg shadow-lg z-30 ${width} ${height} ${background} ${borders && `border ${borders}`} ${
    shown ? "visible" : "hidden"
  } ${className} after:content-[''] after:absolute after:bg-inherit after:dark:bg-inherit after:w-4 after:h-4 after:rotate-45 ${
    borders && `after:border-l after:border-t after:border-inherit`
  }`;
  const TOP_CLASSES = `left-1/2 bottom-full mb-4 animate-grow-popup-top after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:translate-y-1/3 ${
    modifiers.includes("start") ? "!left-0 !animate-grow-popup-top-start after:!left-0 after:!translate-x-1/2" : ""
  } ${modifiers.includes("end") ? "!left-auto !right-0 !animate-grow-popup-top-end after:!left-auto after:!right-0" : ""}`;
  const BOTTOM_CLASSES = `left-1/2 top-full mt-4 animate-grow-popup-bottom after:top-0 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/3 ${
    modifiers.includes("start") ? "!left-0 after:!left-0 !animate-grow-popup-bottom-start after:!translate-x-1/2" : ""
  } ${modifiers.includes("end") ? "!left-auto !right-0 !animate-grow-popup-bottom-end after:!left-auto after:!right-0" : ""}`;
  const LEFT_CLASSES = `right-full top-1/2 mr-4 animate-grow-popup-left after:right-0 after:top-1/2 after:translate-x-1/3 after:-translate-y-1/2 ${
    modifiers.includes("start") ? "!top-0 after:!top-0 !animate-grow-popup-left-start after:!translate-y-1/2" : ""
  } ${modifiers.includes("end") ? "!top-auto !bottom-0 !animate-grow-popup-left-end after:!top-auto after:!bottom-0" : ""}`;
  const RIGHT_CLASSES = `left-full top-1/2 ml-4 animate-grow-popup-right after:left-0 after:top-1/2 after:-translate-x-1/3 after:-translate-y-1/2 ${
    modifiers.includes("start") ? "!top-0 after:!top-0 !animate-grow-popup-right-start after:!translate-y-1/2" : ""
  } ${modifiers.includes("end") ? "!top-auto !bottom-0 !animate-grow-popup-right-end after:!top-auto after:!bottom-0" : ""}`;

  return (
    <div
      className={`${COMMON_CLASSES} ${position === "top" ? TOP_CLASSES : ""} ${position === "bottom" ? BOTTOM_CLASSES : ""} ${position === "left" ? LEFT_CLASSES : ""} ${
        position === "right" ? RIGHT_CLASSES : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Popup;
