import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

import useLogger from "./useLogger";

const useAuth = () => {
  const { showError, showResponse } = useLogger();

  const decodeCookie = (cookie) => {
    let decodedCookie;

    try {
      const encodedCookie = cookie;

      if (encodedCookie) {
        decodedCookie = jwtDecode(encodedCookie);
      }
    } catch (e) {
      console.log(e);
    }

    return decodedCookie;
  };

  const verifyTwoFactorCode = async (event, userId, password, code, rememberMe) => {
    event.preventDefault();

    const newFormData = {
      userId: userId,
      password: password,
      code: code,
      rememberMe: rememberMe
    };

    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + "users/check-phone", newFormData, {
        withCredentials: true
      })
      .then((response) => {
        showResponse(response);
        setTimeout(() => {
          window.location.assign("/dashboard");
        }, 2000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const signUp = async (event, formData, recaptcha) => {
    if (event) {
      event.preventDefault();
    }

    formData.recaptcha = recaptcha.current.getValue();

    if (!formData.notAMinor) {
      //TODO: Change this for a red label saying the checkbox must be checked
      return showError("adultConfirmation");
    }

    if (!formData.termsOfUse) {
      //TODO: Same thing here
      return showError("tosConfirmation");
    }

    if (!formData.recaptcha) {
      return showError("robotConfirmation");
    }

    const newFormData = JSON.parse(JSON.stringify(formData));
    delete newFormData.addr.isHome;
    if (newFormData.addr.apt === "") {
      delete newFormData.addr.apt;
    }

    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "users/register", newFormData, {
        withCredentials: true
      })
      .then((response) => {
        localStorage.setItem("tempEmail", newFormData.email);

        showResponse(response);

        setTimeout(() => {
          window.location.assign("/confirm-email");
        }, 4000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const logOut = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "users/logout", null, {
        withCredentials: true
      })
      .then((response) => {
        localStorage.removeItem("company");

        showResponse(response);
        setTimeout(() => {
          window.location.assign("/");
        }, 2000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const userId = () => {
    const auth = decodeCookie(Cookies.get("auth"));

    if (auth) {
      return auth.userId;
    }

    return null;
  };

  const fullName = () => {
    const auth = decodeCookie(Cookies.get("auth"));

    if (auth) {
      return auth.fullName;
    }

    return null;
  };

  const companies = () => {
    const companies = decodeCookie(Cookies.get("companies"));

    if (companies) {
      return companies.companies;
    }

    return null;
  };

  const profilePic = () => {
    // if profilePicBuffer?.hasPfp) {
    //   let base64ImageString = Buffer.from(
    //     profilePicBuffer.data,
    //     "binary"
    //   ).toString("base64");

    //   return "data:image/png;base64," + base64ImageString;
    // }

    return null;
  };

  const switchToUser = () => {
    localStorage.removeItem("company");
  };

  const switchToCompany = (i) => {
    localStorage.setItem("company", i);
  };

  const isLoggedIn = () => {
    let isLoggedIn = false;

    if (Cookies.get("auth")) {
      const cookieGenerationTimestamp = decodeCookie(Cookies.get("auth")).iat * 1000;
      const userJustLoggedIn = Date.now() - cookieGenerationTimestamp < 1000;
      isLoggedIn = userJustLoggedIn ? false : true;
    }

    return isLoggedIn;
  };

  const isCompany = () => {
    const access = decodeCookie(Cookies.get("access"));

    if (access?.isCompany && localStorage.getItem("company")) {
      return true;
    }

    return null;
  };

  const hasNoSub = () => {
    const access = decodeCookie(Cookies.get("access"));

    if (access?.subscription === "none") {
      return true;
    }

    return null;
  };

  const hasUnconfirmedSub = () => {
    const access = decodeCookie(Cookies.get("access"));

    if (access?.subscription === "unconfirmed") {
      return true;
    }

    return null;
  };

  const hasConfirmedSub = () => {
    const access = decodeCookie(Cookies.get("access"));

    if (access?.subscription === "confirmed") {
      return true;
    }

    return null;
  };

  const isAdmin = () => {
    const access = decodeCookie(Cookies.get("access"));

    if (access) {
      return access.isAdmin;
    }

    return null;
  };

  return {
    verifyTwoFactorCode,
    signUp,
    logOut,
    userId,
    fullName,
    companies,
    profilePic,
    switchToUser,
    switchToCompany,
    isLoggedIn,
    isCompany,
    hasNoSub,
    hasUnconfirmedSub,
    hasConfirmedSub,
    isAdmin
  };
};

export default useAuth;
