import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsInEnglish from "../../../locales/en.json";
import translationsInFrench from "../../../locales/fr.json";

const resources = {
  en: {
    translation: translationsInEnglish
  },
  fr: {
    translation: translationsInFrench
  }
};

const storedLang = localStorage.getItem("lang");
const browserLang = (navigator.language || navigator.userLanguage).split("-")[0];
const displayLang = storedLang ? storedLang : browserLang;
storedLang || localStorage.setItem("lang", browserLang);

i18n.use(initReactI18next).init({
  resources,
  lng: displayLang, //Default language
  debug: false,
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false
  },
  ns: "translation",
  defaultNS: "translation"
});

export default i18n;
