/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";

import useGeneral from "../../../hooks/useGeneral";
import useAuth from "../../../hooks/useAuth";
import useServiceOffers from "../../../hooks/useServiceOffers";
import useLogger from "../../../hooks/useLogger";

import { MdCheck, MdClear, MdOutlineQuestionAnswer } from "react-icons/md";

import Card from "../Card";
import TenderInfoBox from "./TenderInfoBox";
import DateInfoBox from "./DateInfoBox";
import AvatarInfoBox from "./AvatarInfoBox";
import Separator from "../../../components/Separator";
import Button from "../../button/Button";

const SmallServiceOfferCard = ({ title, offer, refreshServiceOffers }) => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { userId } = useAuth();
  const { getStatusColor, showPrice } = useServiceOffers();
  const { showResponse, confirmAction } = useLogger();

  const [conversationExists, setConversationExists] = useState(false);

  const approveOffer = async () => {
    confirmAction(t("confirmApproveOffer"), async () => {
      await axios
        .patch(process.env.REACT_APP_BACKEND_URL + `service-offers/${offer.id}/approve`, null, { withCredentials: true })
        .then((response) => {
          showResponse(response);
          refreshServiceOffers();
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const denyOffer = async () => {
    confirmAction(t("confirmDenyOffer"), async () => {
      await axios
        .patch(process.env.REACT_APP_BACKEND_URL + `service-offers/${offer.id}/deny`, null, { withCredentials: true })
        .then((response) => {
          showResponse(response);
          refreshServiceOffers();
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const startConversation = async () => {
    confirmAction(t("confirmStartConversation", { name: offer.company }), async () => {
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + `conversations/create/${offer.companyId}/${userId()}`, null, { withCredentials: true })
        .then((response) => {
          showResponse(response);
          setTimeout(() => {
            window.location.assign("/dashboard/messages");
          }, 2000);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const checkIfConversationExists = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `conversations/check/${offer.companyId}/${userId()}`, { withCredentials: true })
      .then((response) => {
        setConversationExists(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    checkIfConversationExists();
  }, [offer]);

  if (offer) {
    return (
      <Card
        title={title ? title : <AvatarInfoBox avatar={null} name={t("serviceOfferTitle", { company: offer.company })} modifiers="avatar-sm" />}
        sideText={
          <>
            {t("submitted", { date: formatDate(offer.createdAt) })}
            <TenderInfoBox type="status" info={t("backend.enums.serviceOfferStatuses." + offer.status)} color={getStatusColor(offer.status)} modifiers="no-type" />
          </>
        }
        modifiers="title-xs"
        className={"flex-grow-0 flex-1/6 shadow-md"}
      >
        <div className="flex flex-col h-full space-y-2">
          <Separator />
          <div>
            <p className="whitespace-break-spaces break-words">{offer.message}</p>
          </div>
          <div className="flex items-center space-x-10">
            <p>{showPrice(offer)}</p>
          </div>
          <div className="flex flex-col">
            <div className="flex">
              <DateInfoBox start={offer.date.start} end={offer.date.end} />
            </div>
          </div>
          <Separator />
          {offer.status === "pending" && (
            <div className="flex items-center justify-left space-x-2">
              <Button
                label={
                  <IconContext.Provider value={{ className: "w-full h-2/3" }}>
                    <MdCheck />
                  </IconContext.Provider>
                }
                action={approveOffer}
                background="bg-green-500 dark:bg-green-600 hover:bg-green-400 hover:dark:bg-green-500"
                modifiers="width-2xs height-2xs"
              />
              <Button
                label={
                  <IconContext.Provider value={{ className: "w-full h-2/3" }}>
                    <MdClear />
                  </IconContext.Provider>
                }
                action={denyOffer}
                background="bg-red-500 dark:bg-red-600 hover:bg-red-400 hover:dark:bg-red-500"
                modifiers="width-2xs height-2xs"
              />
              {(offer.status === "pending" || offer.status === "approved") && (
                <>
                  {conversationExists ? (
                    <Button
                      label={
                        <IconContext.Provider value={{ className: "w-full h-2/3" }}>
                          <MdOutlineQuestionAnswer />
                        </IconContext.Provider>
                      }
                      link={"/dashboard/messages"}
                      modifiers="width-2xs height-2xs"
                      background="bg-gray-500 dark:bg-gray-600 hover:bg-gray-400 hover:dark:bg-gray-500"
                    />
                  ) : (
                    <Button
                      label={
                        <IconContext.Provider value={{ className: "w-full h-2/3" }}>
                          <MdOutlineQuestionAnswer />
                        </IconContext.Provider>
                      }
                      action={startConversation}
                      modifiers="width-2xs height-2xs"
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Card>
    );
  }
  
  return null;
  
};

export default SmallServiceOfferCard;
