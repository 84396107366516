import React from "react";

import Card from "../Card";

const CreateTenderReviewCard = ({ children, direction = "vertical" }) => {
  return (
    <Card modifiers="secondary">
      <div
        className={`flex ${
          direction === "vertical" ? "flex-col space-y-4" : direction === "horizontal" ? "flex-row space-x-4" : direction === "grid" ? "gap-4 flex-wrap" : ""
        }`}
      >
        {children}
      </div>
    </Card>
  );
};

export default CreateTenderReviewCard;
