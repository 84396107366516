import React from "react";
import { IconContext } from "react-icons";
import { motion } from "framer-motion";

import { MdArrowForwardIos } from "react-icons/md";

const SettingTab = ({ id, label, from, selectedTabId, link, onClick }) => {
  const isSelected = id === selectedTabId;

  const CLASSES = `flex p-2 rounded-md cursor-pointer hover:bg-neutral-200 hover:dark:bg-neutral-800 ${isSelected ? "!bg-neutral-200 dark:!bg-neutral-800" : ""}`;
  const CONTENT = (
    <>
      <p className="flex-1">{label}</p>
      <div>
        <IconContext.Provider value={{ className: "w-full h-full" }}>
          <motion.div animate={{ rotate: isSelected ? "180deg" : "0deg" }} className="w-full h-full">
            <MdArrowForwardIos />
          </motion.div>
        </IconContext.Provider>
      </div>
    </>
  );

  if (link) {
    return (
      <a href={link} className={CLASSES}>
        {CONTENT}
      </a>
    );
  }

  return (
    <div
      className={CLASSES}
      onClick={() => {
        onClick(from, id);
      }}
    >
      {CONTENT}
    </div>
  );
};

export default SettingTab;
