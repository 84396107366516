import React from "react";

import Separator from "../../components/Separator";

const SettingCategory = ({ label, children }) => {
  return (
    <div className="space-y-1.5">
      <div className="flex items-center">
        <Separator className={"border-neutral-300 max-w-5"} />
        <p className="text-sm text-neutral-500 dark:text-neutral-400 px-2 text-nowrap">{label}</p>
        <Separator className={"flex-1 flex-shrink border-neutral-300"} />
      </div>
      {children}
    </div>
  );
};

export default SettingCategory;
