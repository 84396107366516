/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Separator from "../../Separator";
import { IconContext } from "react-icons";
import { motion } from "framer-motion";

import { MdKeyboardArrowDown } from "react-icons/md";

const DropdownInput = ({ formData, setFormData, id, label, required, disabled, items, hasNone = true, onChange, className, modifiers = "" }) => {
  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const setSelected = (index) => {
    if (index !== 0) {
      setHasSelected(true);
      setSelectedIndex(index);
    } else {
      setHasSelected(false);
      setSelectedIndex(null);
    }

    if (onChange !== undefined) {
      if (index !== 0) {
        onChange(index - 1, null, items);
      } else {
        onChange(undefined);
      }
    } else {
      if (index !== 0) {
        const value = items[index - 1].name;

        setFormData({
          ...formData,
          [id]: value
        });
      } else {
        setFormData({
          ...formData,
          [id]: ""
        });
      }
    }
  };

  const previous = () => {
    if (selectedIndex !== 0 && selectedIndex !== null) {
      setSelected(selectedIndex - 1);
    } else {
      setSelected(0);
    }
  };

  const next = () => {
    if (selectedIndex === null) {
      setSelected(1);
    } else if (selectedIndex !== items.length) {
      setSelected(selectedIndex + 1);
    }
  };

  const onClick = () => {
    if (!disabled) {
      setIsFocused(!isFocused);
    }
  };

  const onKeyDown = (event) => {
    if (!disabled) {
      switch (event.keyCode) {
        // Enter (13), Space (32)
        case 13:
        case 32:
          onClick();
          break;

        // Escape (27)
        case 27:
          setIsFocused(false);
          break;

        // ArrowLeft (37), ArrowUp (38)
        case 37:
        case 38:
          previous();
          break;

        // ArrowRight (39), ArrowDown (40)
        case 39:
        case 40:
          next();
          break;

        default:
          break;
      }
    }
  };

  const handleSelect = (event) => {
    const index = parseInt(event.target.getAttribute("index"));

    setSelected(index);
    setIsFocused(false);
  };

  useEffect(() => {
    if (formData) {
      const value = formData[id];
      if (value !== "") {
        const item = items.find((item) => item.name === value);

        setSelectedIndex(items.indexOf(item) + 1);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedIndex !== null) {
      setHasSelected(true);
    }
  }, [selectedIndex]);

  return (
    <div
      className={`relative w-full ${modifiers.includes("width-xs") ? "max-w-xs" : ""} ${modifiers.includes("width-sm") ? "max-w-sm" : ""} ${
        modifiers.includes("width-md") ? "max-w-md" : ""
      } ${modifiers.includes("width-lg") ? "max-w-lg" : ""} ${modifiers.includes("width-xl") ? "max-w-xl" : ""} ${modifiers.includes("width-1/2") ? "max-w-1/2" : ""} ${
        modifiers.includes("width-1/4") ? "max-w-1/4" : ""
      } ${modifiers.includes("width-3/4") ? "max-w-3/4" : ""}`}
    >
      <label
        htmlFor={id}
        className={`absolute top-1/2 -translate-y-1/2 ml-2 px-1 text-neutral-500 dark:text-neutral-300 transition-[top,font-size] pointer-events-none ${
          hasSelected ? `text-xs bg-white dark:bg-neutral-750 !top-0 ${modifiers.includes("secondary") ? "!bg-neutral-150 dark:!bg-neutral-700" : ""}` : ""
        } ${disabled ? `!text-neutral-350 dark:!text-neutral-500` : ""}`}
      >
        {label}
      </label>
      <div
        id={id}
        className={`flex bg-transparent outline-none border dark:border-neutral-700 focus:border-neutral-500 focus:dark:border-neutral-300 p-3 shadow-sm rounded-lg cursor-pointer ${
          isFocused && "!border-neutral-500 dark:!border-neutral-300 !rounded-b-none"
        } ${disabled ? `!bg-neutral-150 dark:!bg-neutral-700 !text-neutral-450 dark:!text-neutral-400 !cursor-default pointer-events-none` : ""} ${className}`}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onBlur={() => {
          setIsFocused(false);
        }}
        tabIndex={0}
      >
        <p className={`flex-1 ${!hasSelected ? "invisible" : ""}`}>{hasSelected && selectedIndex !== 0 ? items[selectedIndex - 1].label : t("none")}</p>
        <div className="w-6 h-6">
          <IconContext.Provider value={{ className: "w-full h-full" }}>
            <motion.div animate={{ rotate: isFocused ? "180deg" : "0deg" }} className="w-full h-full">
              <MdKeyboardArrowDown />
            </motion.div>
          </IconContext.Provider>
        </div>
      </div>
      <ul
        className={`absolute bg-neutral-50 dark:bg-neutral-750 w-full min-h-10 max-h-64 p-1 space-y-1 overflow-y-auto scrollbar-preset-thin rounded-b-lg shadow-2xl z-10 ${
          isFocused ? "visible border border-t-0 border-neutral-500 dark:border-neutral-300" : "hidden"
        }`}
      >
        {hasNone && (
          <>
            <li
              id={null}
              index={0}
              onMouseDown={handleSelect}
              className={`p-2 hover:bg-neutral-200 dark:hover:bg-neutral-800 hover:cursor-pointer rounded-md ${
                selectedIndex - 1 === -1 ? "!bg-neutral-200 dark:!bg-neutral-800" : ""
              }`}
            >
              {t("none")}
            </li>
            <Separator />
          </>
        )}
        {items.map((item, i) => {
          if (item.label?.includes("t('") && item.label?.includes("')")) {
            item.label = t(item.label.substring(3, item.label.length - 2));
          }

          return (
            <li
              key={item.name}
              id={item.name}
              index={i + 1}
              onMouseDown={handleSelect}
              className={`p-2 hover:bg-neutral-200 dark:hover:bg-neutral-800 cursor-pointer rounded-md ${selectedIndex - 1 === i ? "!bg-neutral-200 dark:!bg-neutral-800" : ""}`}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DropdownInput;
