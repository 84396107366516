
import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="max-w-4xl mx-auto p-5 space-y-4 mt-36 text-justify">

      <h1 className="text-3xl font-bold mb-4">Termes et Conditions</h1>
      <p>Dernière mise à jour : 29 février 2024</p>
      <p>
        Bienvenue sur notre application web de soumission en ligne : Hoptop. Les présents termes et conditions régissent votre utilisation de l’application, y compris tout contenu, fonctionnalités et services offerts par celle-ci. Veuillez lire attentivement ces termes avant de l’utiliser.
      </p>
      <br></br>
      <h2 className="text-2xl font-semibold">1. Droit d’auteur</h2>
      <p>
        Cette application web est la propriété exclusive de l’entreprise Hoptop. La présente application web et son contenu sont protégés par le droit d’auteur. Tout matériel (marque, logo, nom, etc.) sous forme de texte, de graphique, d’audio, de vidéo, d’image, d’illustration, de photographie, de base de données, de carte, de fichier, de logiciel, etc. incluant la manière avec laquelle le contenu est présenté, est protégé en vertu des lois sur le droit d’auteur et appartiennent à Hoptop.
      </p>
      <br></br>
      <h2 className="text-2xl font-semibold">2. Acceptation des termes</h2>
      <p>
        En accédant et en utilisant Hoptop, vous acceptez d’être lié par les présents termes et conditions, ainsi que par notre politique de confidentialité. Si vous n’acceptez pas ces termes, veuillez ne pas utiliser l’application. Toute personne naviguant sur ce site reconnaît avoir lu, compris et accepté les termes et conditions. Les présents termes et conditions peuvent être modifiés sans préavis ni délai par Hoptop, et ce, à sa seule discrétion.
      </p>
      <br></br>
      <h2 className="text-2xl font-semibold">3. Utilisation de l’application web</h2>
      <p>
        Vous acceptez d’utiliser Hoptop uniquement à des fins légales et conformément à ces termes et conditions. Vous êtes responsable de tout contenu que vous soumettez via Hoptop et vous garantissez que ce contenu soit légal, précis et conforme aux présentes conditions. Toute personne utilisant l’application web Hoptop reconnaît et accepte que toute information qu’elle peut transmettre de quelque façon que ce soit à Hoptop ou une autre personne, entreprise, etc. affiliée ou associée à Hoptop devienne automatiquement la propriété de Hoptop, et ce, sans aucune compensation que ce soit de la part de Hoptop.
      </p>
      <p>
        Les utilisateurs d’Hoptop s’engagent à un respect mutuel. Tout contenu partagé, notamment dans le système de clavardage, qu’il s’agisse de messages, d’images, ou autres formes de communication, doit être conforme aux normes de décence, de courtoisie et de respect des droits d’autrui. Tout langage diffamatoire, injurieux, discriminatoire, ou obscène est strictement prohibé. Dans le cas où un utilisateur ne respecterait pas ces principes fondamentaux, Hoptop se réserve le droit de prendre des mesures appropriées, notamment la suspension ou la suppression de comptes.
      </p>
      <br></br>
      <h2 className="text-2xl font-semibold">4. Confidentialité</h2>
      <p>
        Nous respectons votre vie privée et nous nous engageons à protéger les informations personnelles que vous fournissez via l’application web Hoptop. Néanmoins, Hoptop se dégage de toute responsabilité quant à la confidentialité des informations transmises entre une entreprise et un particulier et ne peut garantir la confidentialité des deux parties en ce sens. Hoptop n’utilise pas de partenaires publicitaires et autres tiers pouvant installer des témoins ou des pixels invisibles sur certaines pages...
      </p>
      <p>
        Les renseignements personnels collectés et stockés par l’application web se limitent aux noms, adresses, adresses courriel et numéros de téléphones des usagers. Ces informations sont utilisées afin de pouvoir vous identifier (création de profil, demande de soumission, etc.) Nous stockons aussi vos historiques de conversation sur l’application, les plans, les photos, les descriptions de projets, etc. qui sont partagés entre les particuliers et les entreprises à travers l’application.
      </p>
      <br></br>
      <h2 className="text-2xl font-semibold">5. Limitation de responsabilité</h2>
      <p>
        L’application web Hoptop est fournie telle quelle, sans aucune garantie, expresse ou implicite. En aucun cas, nous ne serons responsables de tout dommage, y compris, mais sans s’y limiter, les dommages directs, indirects, accessoires, spéciaux, punitifs ou consécutifs découlant de votre utilisation de Hoptop.
      </p>
      <p>
        Hoptop agit en tant qu’intermédiaire entre les entreprises et les particuliers et se dégage légalement de toutes responsabilités liées aux ententes entre ces deux parties. Toutes ententes contractuelles entre un particulier et une entreprise ne sont pas de la responsabilité d’Hoptop. L’application web propose un service de recherche et de sélection afin de mettre des clients et des entreprises en relation. Hoptop se limite donc à une prestation de courtage et n’est en aucun cas partie, ne ...
      </p>
      <br></br>
      <h2 className="text-2xl font-semibold">6. Droit applicable</h2>
      <p>
        Les présentes conditions sont régies et interprétées conformément aux lois en vigueur dans la province de Québec, au Canada. Tout litige découlant de ces termes et conditions sera soumis à la juridiction exclusive des tribunaux compétents de la province de Québec.
      </p>
      <br></br>
    </div>
  );
}

export default TermsAndConditions;