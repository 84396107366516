import { useContext } from "react";
import { useTranslation } from "react-i18next";

import useGeneral from "../../hooks/useGeneral";
import useStripe from "../../hooks/useStripe";
import { SettingsFormDataContext } from "../contexts/SettingsFormDataContext";

import MFAStatus from "../../components/settings/elements/MFAStatus";

const useJSLabels = () => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { getSubscription, getTierAndCycle } = useStripe();
  const settingsFormDataContext = useContext(SettingsFormDataContext);

  const subscriptionCurrentText = () => {
    const subscription = getSubscription();

    return `${t(subscription?.tier)} (${t(subscription?.cycle)})`;
  };

  const subscriptionStatusText = () => {
    return t(`backend.enums.subscriptionStatuses.${getSubscription()?.status}`);
  };

  const subscriptionStartedOnText = () => {
    return formatDate(getSubscription()?.startedOn);
  };

  const subscriptionRenewsOnText = () => {
    return formatDate(getSubscription()?.renewsOn);
  };

  const subscriptionEndsOnText = () => {
    return formatDate(getSubscription()?.endsOn);
  };

  const subscriptionStartTierText = () => {
    const { tier, cycle } = getTierAndCycle();

    return `${t(tier)} (${t(cycle)})`;
  };

  const mfaAuthenticatorIsSetup = () => {
    return <MFAStatus status={settingsFormDataContext.formData.mfaAuthenticatorIsSetup} />;
  };

  const mfaSMSIsSetup = () => {
    return <MFAStatus status={settingsFormDataContext.formData.mfaSMSIsSetup} />;
  };

  const mfaEmailIsSetup = () => {
    return <MFAStatus status={settingsFormDataContext.formData.mfaEmailIsSetup} />;
  };

  const mfaCodesIsSetup = () => {
    return <MFAStatus status={settingsFormDataContext.formData.mfaCodesIsSetup} />;
  };

  return {
    subscriptionCurrentText,
    subscriptionStatusText,
    subscriptionStartedOnText,
    subscriptionRenewsOnText,
    subscriptionEndsOnText,
    subscriptionStartTierText,
    mfaAuthenticatorIsSetup,
    mfaSMSIsSetup,
    mfaEmailIsSetup,
    mfaCodesIsSetup
  };
};

export default useJSLabels;
